import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../common/AuthProvider';
import local_storage from '../../common/localStorage';

function Error401() {
  const history = useHistory();
  const { setAuth } = useAuthContext();

  local_storage.clear();
  setAuth(null);
  history.push('/');
  return <div></div>;
}

export default Error401;
