import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const useLocalStorage = (key, initialValue) => {
  const location = useLocation();
  const keyWithLocation = `${location.pathname}:${key}`;

  const [storeValue, setStoreValue] = useState(() => {
    try {
      let value = localStorage.getItem(keyWithLocation);
      if (value) {
        return JSON.parse(value) || initialValue;
      } else return initialValue;
    } catch (e) {
      return initialValue;
    }
  });

  const setValue = data => {
    try {
      localStorage.setItem(keyWithLocation, JSON.stringify(data));
      setStoreValue(data);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleStorageEvent = useCallback(event => {
    if (
      event.keyWithLocation === keyWithLocation &&
      event.newValue !== storeValue
    ) {
      setStoreValue(event.newValue);
    }
  });

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent);
    return () => window.removeEventListener('storage', handleStorageEvent);
  }, [handleStorageEvent]);

  return [storeValue, setValue];
};
