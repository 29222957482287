import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';

import IconDashboard from '@material-ui/icons/Dashboard';
import IconShoppingCart from '@material-ui/icons/ShoppingCart';
import IconPeople from '@material-ui/icons/People';
import IconBarChart from '@material-ui/icons/BarChart';
import IconLibraryBooks from '@material-ui/icons/LibraryBooks';

import AppMenuItem from './AppMenuItem';

const AppMenu = ({ items, hover, expand, setExpand }) => {
  if (!expand) expand = [];
  const classes = useStyles();
  // const [expand, setExpand] = useState(() => items.map(item => false));

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      {items.map((item, index) => (
        <AppMenuItem
          {...item}
          hover={hover}
          key={index}
          index={index}
          expand={expand[index]}
          setExpand={setExpand}
        />
      ))}
    </List>
  );
};

AppMenu.defaultProps = {
  expand: () => null,
  setExpand: () => null
};

const drawerWidth = 240;

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: 280
    },
    navList: {
      width: drawerWidth
    },
    menuItem: {
      width: drawerWidth
    },
    menuItemIcon: {
      color: '#B1B1B1'
    }
  })
);

export default AppMenu;
