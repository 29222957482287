import React, { useState } from 'react';
import { Checkbox, Button, Text } from '@blueprintjs/core';
import { Table } from 'rsuite';
import './DataTable.css';
import { Input } from '../Forms/Forms';
import { useHistory } from 'react-router-dom';

const { Column, HeaderCell, Cell } = Table;

function Core(props) {
  const {
    // data
    data,
    columns,
    // callbacks
    onSave,
    onDelete,
    onEdit,
    rowChecker,
    onSortColumn,
    // flags
    loading,
    // actions
    actionCell,
    style,
    // others
    actionColumnWidth,
    path,
    ...others
  } = props;
  const history = useHistory();

  //---------------------------row checkboxes
  const { checks, check, checkAll } = rowChecker || {};

  //--------------------------cell editting
  const [editRow, setEditRow] = useState();

  //------------------------events
  const _onEdit = rowData => {
    if (onEdit) onEdit({ ...rowData });
    else setEditRow({ ...rowData });
  };

  const _onSave = () => {
    if (onSave)
      onSave(
        { ...editRow },
        () => setEditRow(),
        errors => setEditRow({ ...editRow, errors })
      );
  };

  const _onDelete = rowData => {
    if (onDelete) onDelete({ ...rowData });
  };

  //------------------------sort
  const [sort, setSort] = useState({
    sortColumn: null,
    sortType: 'asc'
  });

  //------------------------render
  const selectedColumns =
    columns.length === 0 ? (
      <Column flexGrow={1}>
        <HeaderCell></HeaderCell>
        <Cell />
      </Column>
    ) : (
      columns.map((column, columnIndex) => {
        let dataFn, dataKey;
        if (typeof column.data === 'function') {
          dataFn = column.data;
          dataKey = column.dataKey;
        } else dataKey = column.data;

        return (
          <Column
            key={column.header}
            // align="center"
            minWidth={200}
            {...column.props}
          >
            <HeaderCell>
              <div className="base-table-header">
                <Text title={column.header} ellipsize>
                  {column.header}
                </Text>
              </div>
            </HeaderCell>
            <EditCell
              editable={column.editable}
              dataKey={dataKey}
              dataFn={dataFn}
              columnIndex={columnIndex}
              editRow={editRow}
              setEditRow={setEditRow}
            />
          </Column>
        );
      })
    );

  return (
    <Table
      rowKey="id"
      // defaultExpandAllRows
      data={data}
      loading={loading}
      renderEmpty={noData =>
        loading ? null : (
          <p style={{ position: 'absolute', left: '45%', top: '45%' }}>
            Không có dữ liệu
          </p>
        )
      }
      autoHeight
      wordWrap
      sortColumn={sort.sortColumn}
      sortType={sort.sortType}
      onSortColumn={(sortColumn, sortType) => {
        if (sortColumn !== sort.sortColumn) {
          setSort({ sortColumn: sortColumn, sortType: 'asc' });
          onSortColumn(sortColumn, 'asc');
        } else {
          if (sort.sortType === 'desc') {
            setSort({ sortColumn: null, sortType: 'asc' });
            onSortColumn(null, null);
          } else {
            setSort({ sortColumn: sortColumn, sortType: sortType });
            onSortColumn(sortColumn, sortType);
          }
        }
      }}
      onRowClick={path ? row => history.push(`${path}/${row.id}`) : null}
      // bordered
      // cellBordered
      {...others}
    >
      {rowChecker && (
        <Column width={50} align="center" fixed>
          <HeaderCell>
            <Checkbox
              indeterminate={checks?._indeterminate}
              checked={checks?._all || false}
              onChange={checkAll}
            />
          </HeaderCell>
          <CheckboxCell checks={checks} check={check} />
        </Column>
      )}
      {selectedColumns}
      {(actionCell || onSave) && (
        <Column width={actionColumnWidth || 100} align="right" fixed={'right'}>
          <HeaderCell></HeaderCell>
          <ActionCell
            component={actionCell}
            setEditRow={setEditRow}
            editRow={editRow}
            onSave={_onSave}
            onEdit={_onEdit}
            onDelete={_onDelete}
          />
        </Column>
      )}
    </Table>
  );
}

//----------------------------Custom Cells
function EditCell(props) {
  const {
    editable,
    rowData,
    dataKey,
    dataFn,
    columnIndex,
    editRow,
    setEditRow,
    ...otherProps
  } = props;

  const onChange = value => {
    setEditRow({ ...editRow, [dataKey]: value });
  };

  return (
    <Cell {...otherProps} rowData={rowData} dataKey={null}>
      {editRow?.id === rowData.id && editable ? (
        <Input
          variant="standard"
          type={editable}
          autoFocus={columnIndex === 0 ? true : false}
          value={editRow[dataKey]}
          onChange={onChange}
          error={editRow.errors?.[dataKey]?.[0]}
        />
      ) : dataFn ? (
        <span
          onClick={e =>
            typeof dataFn(rowData) === 'string' ? null : e.stopPropagation()
          }
        >
          {dataFn(rowData)}
        </span>
      ) : (
        rowData[dataKey]
      )}
    </Cell>
  );
}

function ActionCell(props) {
  const {
    component,
    rowData,
    editRow,
    setEditRow,
    onSave,
    onEdit,
    onDelete,
    ...otherProps
  } = props;
  if (!component) {
    //---------default buttons
    return editRow?.id === rowData.id ? (
      <Cell {...otherProps}>
        <Button minimal icon="saved" intent="success" onClick={onSave} />
        <Button minimal icon="cross" onClick={() => setEditRow()} />
      </Cell>
    ) : (
      <Cell {...otherProps}>
        <Button minimal icon="edit" onClick={() => onEdit(rowData)} />
        <Button minimal icon="trash" onClick={() => onDelete(rowData)} />
      </Cell>
    );
  } else {
    const Component = component;
    //---------------custom buttons
    return (
      <Cell {...otherProps}>
        <div onClick={e => e.stopPropagation()}>
          <Component rowData={rowData} />
        </div>
      </Cell>
    );
  }
}

function CheckboxCell(props) {
  const { rowData, checks, check, ...otherProps } = props;

  return (
    <Cell {...otherProps} onClick={e => e.stopPropagation()}>
      <div>
        <Checkbox
          key={rowData.id}
          checked={checks?.[rowData.id] || false}
          onChange={() => check(rowData.id)}
        />
      </div>
    </Cell>
  );
}

export default React.memo(Core);
