import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useDialog } from '../../shared/Dialog';
import { useAPI, useFetch } from '../../common/hooks/api';
import useCheck from '../../common/hooks/useCheck';
import { Button, ButtonGroup } from '@blueprintjs/core';
import FieldMenu from '../../shared/FieldMenu';
import DataTable from '../../shared/table/DataTable';
import Pagination from '../../shared/Pagination';
import './Timekeeper.css';
import MyMap from './MyMap';
import Moment from 'moment';
import Filter from '../../shared/Filter';
import { Popover } from '@material-ui/core';

function TimekeeperList() {
  const api = useAPI();
  const dialog = useDialog();
  const [params, setParams] = useState({});
  const fetcher = useFetch(['get', 'timekeeper', JSON.stringify({ params })]);
  const columns = useMemo(
    () => [
      {
        header: 'Thời gian',
        data: row => Moment(row.created_at).format('DD-MM-YYYY  HH:mm:ss'),
        dataKey: 'created_at',
        props: {
          flexGrow: 1,
          align: 'left'
        },
        checked: true
      },
      {
        header: 'Tên nhân viên',
        dataKey: 'user_name',
        data: row => row.user.name,
        props: {
          flexGrow: 1,
          align: 'left'
        },
        checked: true
      },
      {
        header: 'Số điện thoại',
        dataKey: 'user_phone',
        data: row => row.user.phone,
        props: {
          flexGrow: 1,
          align: 'left'
        },
        checked: true
      },
      {
        header: 'Ghi chú',
        dataKey: 'note',
        data: 'note',
        props: {
          flexGrow: 2,
          align: 'left'
        },
        checked: true
      }
    ],
    []
  );

  const inputs = useMemo(() => [
    {
      label: 'Tên nhân viên',
      field: 'name',
      type: 'text',
      grid: { xs: 12, sm: 12, md: 12 },
      checked: true
    },
    {
      label: 'Số điện thoại',
      field: 'phone',
      type: 'text',
      grid: { xs: 12, sm: 12, md: 12 },
      checked: true
    },
    {
      label: 'Trước ngày',
      field: 'before',
      type: 'date',
      grid: { xs: 12, sm: 12, md: 12 },
      checked: true
    },
    {
      label: 'Sau ngày',
      field: 'after',
      type: 'date',
      grid: { xs: 12, sm: 12, md: 12 },
      checked: true
    }
  ]);

  const columnChecker = useCheck(columns, 'header');
  const rowChecker = useCheck(fetcher.data?.data, 'id');

  const res = fetcher.data?.data.map(e => ({
    id: e.id,
    name: e.user.name,
    created_at: Moment(e.created_at).format('DD-MM-YYYY HH:mm:ss'),
    phone: e.user.phone,
    note: e.note,
    location: { latitude: e.latitude, longitude: e.longitude },
    file: e.files[0]?.path
  }));

  const viewmap = callback => {
    dialog.show({
      dialogProps: {
        icon: 'map-marker',
        title: 'Địa điểm'
      },
      body: <MyMap location={callback} />
    });
  };

  const viewImg = callback => {
    dialog.show({
      dialogProps: {
        icon: 'media',
        title: '',
        style: {
          width: '60%'
        }
      },
      body: () => {
        if (callback?.length > 0) {
          return (
            <Fragment>
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  '/storage/' +
                  callback[0]?.path
                }
                style={{ width: '100%' }}
              />
            </Fragment>
          );
        } else {
          return <div>Không có dữ liệu</div>;
        }
      }
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState({
    column: false,
    menu: false
  });
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = e => {
    setAnchorEl(null);
    setOpen({ ...open, [e]: false });
  };
  async function handleExport(ids, page) {
    try {
      let res = await api.fetcher(
        'post',
        'timekeeperExport',
        {
          columnChecker: columnChecker.checkedItems.map(e => ({
            header: e.header,
            field: e.dataKey,
            id: e.id,
            group_id: e.group_id
          })),
          ...params,
          ids: ids?.checkedItems.map(e => e.id),
          page: page
        },
        {
          responseType: 'blob'
        }
      );
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'lichsuchamcong.xlsx');
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {}
  }
  return (
    <Fragment>
      <div className="toolbar">
        <div className="toolbar-group-actions">
          {rowChecker.checkedItems.length > 0 && (
            <Fragment>
              {/*<Button icon="trash" text="Xoá" />*/}
              {/*<Divider />*/}
              <Button
                icon="download"
                text="Xuất dữ liệu"
                onClick={() => handleExport(rowChecker, fetcher?.data?.page)}
              />
            </Fragment>
          )}
        </div>
        <div className="toolbar-group-actions">
          <ButtonGroup>
            <Filter
              onSubmit={setParams}
              inputs={inputs}
              loading={fetcher.loading}
            />
            <Button
              icon="list-columns"
              onClick={event => {
                handleClick(event);
                setOpen({ ...open, column: true });
              }}
            />
            <Popover
              className="simple-popover"
              open={open.column}
              anchorEl={anchorEl}
              onClose={() => handleClose('column')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <FieldMenu
                label="header"
                fields={columns}
                checker={columnChecker}
              />
            </Popover>
          </ButtonGroup>
        </div>
      </div>

      <DataTable
        loading={fetcher.loading}
        data={fetcher?.data?.data || []}
        columns={columnChecker.checkedItems}
        rowChecker={rowChecker}
        actionCell={({ rowData }) => (
          <Fragment>
            <Button
              minimal
              icon="map-marker"
              onClick={() => viewmap(rowData)}
              title={'Vị trí'}
            />
            <Button
              minimal
              icon="media"
              onClick={() => viewImg(rowData.files)}
              title={'Ảnh chụp tại thời điểm checkin'}
            />
          </Fragment>
        )}
      />
      <Pagination
        lastPage={fetcher.data?.last_page}
        currentPage={fetcher.data?.current_page}
        total={fetcher.data?.total}
        setParams={setParams}
        params={params}
      />
    </Fragment>
  );
}
export default TimekeeperList;
