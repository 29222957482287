import React from 'react';

function Error() {
  return (
    <React.Fragment>
      <h1>UNAUTHENTICATED || SERVER ERROR?</h1>
      <img src="./server_error.jpg" alt="wtf" />
    </React.Fragment>
  );
}

export default Error;
