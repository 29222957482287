import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import useDebouncedQuery from '../../common/hooks/useDebouncedQuery';
import TextField from '../Forms/TextField';
import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

import './QuerySelect.css';

export default React.memo(props => {
  const {
    endpoint,
    value,
    onChange,
    onChangeObj,
    labelField,
    queryField = props.labelField,
    valueField,
    renderOption,
    inputProps,
    ...otherProps
  } = props;

  const [query, setQuery] = useState();
  const [ownVal, setOwnVal] = useState(null);

  const { items, loading } = useDebouncedQuery(endpoint, query, items => {
    setOwnVal(items[0]);
    setQuery();
  });

  useEffect(() => {
    if (typeof value === 'object') setOwnVal(value);
    else {
      if (!value) setOwnVal(null);
      if (value && value !== ownVal?.[valueField] && items.length > 0) {
        const checkInFetchedItems = items.find(
          item => item[valueField] === value
        );
        if (checkInFetchedItems) setOwnVal(checkInFetchedItems);
        else setQuery({ set: [value] });
      }
    }
  }, [value, ownVal, items]);

  return (
    <Autocomplete
      classes={{ paper: 'query-select-menu' }}
      size="small"
      openOnFocus
      loading={loading}
      loadingText="Đang tải..."
      noOptionsText="Không tìm thấy"
      renderInput={params => {
        return (
          <TextField
            onChange={value => setQuery({ [queryField]: value })}
            {...params}
            {...otherProps}
            InputLabelProps={{ shrink: !!value || query }}
            inputProps={{ ...params.inputProps, ...inputProps }}
          />
        );
      }}
      options={items}
      value={ownVal}
      renderOption={renderOption}
      getOptionLabel={option => option?.[labelField]}
      onChange={(event, value, reason) => {
        if (onChangeObj) return onChangeObj(value);
        setOwnVal(value);
        onChange(value?.[valueField]);
      }}
      onClose={() => {
        setQuery();
      }}
      {...otherProps}
    />
  );
});
