import React from 'react';
import { ProgressBar, Intent } from '@blueprintjs/core';

function AppLoading() {
  return (
    <div className="loading-container">
      <h6>Vui lòng chờ...</h6>
      <ProgressBar className="progress-bar" intent={Intent.PRIMARY} />
    </div>
  );
}
export default AppLoading;
