import React, { useState } from 'react';
import { Card } from '@blueprintjs/core';
import './DataTable.css';
import Core from './Core';

export default function DataTable(props) {
  return (
    <Card elevation={1}>
      <Core {...props} />
    </Card>
  );
}
