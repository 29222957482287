import { lazy } from 'react';
import TimekeeperList from '../views/Timekeeper/TimekeeperList';

const Logout = lazy(() => import('../views/Login/Logout'));

const RoleForm = lazy(() => import('../views/Admin/Role/RoleForm'));
const RoleIndex = lazy(() => import('../views/Admin/Role/RoleIndex'));

const ContractList = lazy(() => import('../views/Contract2/Contract'));
const ContractForm = lazy(() => import('../views/Contract2/Form'));

const UserList = lazy(() => import('../views/User/UserList'));

const CustomerAtvin = lazy(() => import('../views/Customer2/CustomerAtvin'));
const Potentialcustomer = lazy(() =>
  import('../views/Customer2/CustomerPotential')
);
const CustomerForm = lazy(() => import('../views/Customer2/CustomerForm'));
const ConstructionForm = lazy(() => import('../views/Construction2/Form'));
const MaintenanceQuoteTemplate = lazy(() =>
  import('../views/Quote/MaintainanceQuoteTemplate')
);
const AuditList = lazy(() => import('../views/Admin/Audit/AuditList'));
const DocumentCompanyList = lazy(() =>
  import('../views/Admin/DocumentCompany/List')
);
const UserViewDocumentCompanyList = lazy(() =>
  import('../views/Admin/DocumentCompany/UserView')
);
const UserViewDocumentCompanyView = lazy(() =>
  import('../views/Admin/DocumentCompany/UserViewDocument')
);
const DocumentCompanyForm = lazy(() =>
  import('../views/Admin/DocumentCompany/Form')
);
const FeedBackList = lazy(() => import('../views/FeedBack/FeedBackList'));
const FeedBackStore = lazy(() => import('../views/FeedBack/FeedBackStore'));
const FeedBackView = lazy(() => import('../views/FeedBack/FeedBackView'));
const Attribute = lazy(() => import('../views/Attribute/Attribute'));
const CampaignSmsIndex = lazy(() =>
  import('../views/CampaignSms/CampaignSmsList')
);
const CampaignSmsStore = lazy(() =>
  import('../views/CampaignSms/CampaignSmsStore')
);
const CampaignSmsUpdate = lazy(() =>
  import('../views/CampaignSms/CampaignSmsUpdate')
);
const Settings = lazy(() => import('../views/Setting/Settings'));
const EmailTemplateList = lazy(() =>
  import('../views/EmailTemplate/EmailTemplateList')
);
const EmailTemplateForm = lazy(() =>
  import('../views/EmailTemplate/EmailTemplateForm')
);
const CampaignEmailList = lazy(() =>
  import('../views/CampaignEmail/CampaignEmailList')
);
const CampaignEmailForm = lazy(() =>
  import('../views/CampaignEmail/CampaignEmailForm')
);

const Dashboard = lazy(() => import('../views/Dashboard/Dashboard'));
const Report = lazy(() => import('../views/Report/Report'));
const ConstructionList = lazy(() =>
  import('../views/Construction2/Construction')
);
const Target = lazy(() => import('../views/Target/Target'));

const TaskUser = lazy(() => import('../views/User/TaskUser'));
const Tasks = lazy(() => import('../views/Task/Tasks'));
const BannerList = lazy(() => import('../views/Banner/BannerList'));
const BannerForm = lazy(() => import('../views/Banner/BannerForm'));
const routes = [
  // { path: '/test', name: 'Trang chủ', component: TaskPublic },
  { path: '/', name: 'Trang chủ', component: Dashboard },
  { path: '/logout', name: 'Logout', component: Logout },
  //---------------------User & role
  {
    path: '/user',
    perm: ['user_view', 'user_manage'],
    name: 'Danh sách nhân viên',
    component: UserList
  },
  {
    path: '/role',
    name: 'Danh sách nhóm người dùng',
    perm: 'role_manage',
    component: RoleIndex
  },
  {
    path: '/role/create',
    name: 'Thêm nhóm người dùng',
    perm: 'role_manage',
    component: RoleForm
  },
  {
    path: '/role/:roleId',
    name: 'Chi tiết nhóm người dùng',
    perm: 'role_manage',
    component: RoleForm
  },
  //--------------------Contract
  {
    path: '/contract',
    perm: ['contract_view', 'contract_manage', 'contract_all'],
    name: 'Danh sách hợp đồng',
    component: ContractList
  },
  {
    path: '/contract/create',
    perm: 'contract_manage',
    name: 'Thêm hợp đồng mới',
    component: ContractForm
  },
  {
    path: '/contract/:id',
    perm: ['contract_view', 'contract_manage', 'contract_all'],
    name: 'Thông tin hợp đồng',
    component: ContractForm
  },
  //--------------------Customer
  {
    path: '/customerAtvin',
    perm: ['customer_view', 'customer_manage', 'customer_all'],
    name: 'Khách hàng của Atvin',
    component: CustomerAtvin
  },
  {
    path: '/customerPotential',
    perm: ['customer_view', 'customer_manage', 'customer_all'],
    name: 'Khách hàng tiềm năng',
    component: Potentialcustomer
  },
  {
    path: '/customerAtvin/create',
    perm: 'customer_manage',
    name: 'Thêm mới khách hàng',
    component: CustomerForm
  },
  {
    path: '/customerPotential/create',
    perm: 'customer_manage',
    name: 'Thêm mới khách hàng',
    component: CustomerForm
  },
  {
    path: '/customerAtvin/:id',
    perm: ['customer_view', 'customer_manage', 'customer_all'],
    name: 'Thông tin khách hàng',
    component: CustomerForm
  },
  {
    path: '/customerPotential/:id',
    perm: ['customer_view', 'customer_manage', 'customer_all'],
    name: 'Thông tin khách hàng',
    component: CustomerForm
  },
  {
    path: '/customer/:id',
    perm: ['customer_view', 'customer_manage', 'customer_all'],
    name: 'Thông tin khách hàng',
    component: CustomerForm
  },

  //--------------------Construction
  {
    path: '/construction',
    perm: ['construction_view', 'construction_manage', 'construction_all'],
    name: 'Danh sách công trình',
    component: ConstructionList
  },
  {
    path: '/construction/create',
    perm: 'construction_manage',
    name: 'Thêm mới công trình',
    component: ConstructionForm
  },
  {
    path: '/construction/:id',
    perm: ['construction_view', 'construction_manage', 'construction_all'],
    name: 'Thông tin công trình',
    component: ConstructionForm
  },
  //---------------------Task
  {
    path: '/taskInternal',
    perm: ['task_view', 'task_manage', 'task_all'],
    name: 'Công việc nội bộ',
    component: Tasks
  },
  {
    path: '/taskPublic',
    perm: ['task_view', 'task_manage', 'task_all'],
    name: 'Công việc giám sát',
    component: Tasks
  },
  {
    path: '/timekeeper',
    perm: 'timekeeper_view',
    name: 'Lịch sử chấm công',
    component: TimekeeperList
  },
  //---------------------------------SMS
  {
    path: '/campaignSms',
    perm: 'campaignSms_manage',
    name: 'SMS Chăm sóc khách hàng',
    component: CampaignSmsIndex
  },
  {
    path: '/campaignSms/create',
    perm: 'campaignSms_manage',
    name: 'Thêm mới nhiệm vụ tin nhắn sms',
    component: CampaignSmsStore
  },
  {
    path: '/campaignSms/:id',
    perm: 'campaignSms_manage',
    name: 'Xem nhiệm vụ tin nhắn sms',
    component: CampaignSmsUpdate
  },
  //---------------------------------Email
  {
    path: '/emailTemplate',
    perm: 'campaignEmail_manage',
    name: 'Danh sách mẫu email',
    component: EmailTemplateList
  },
  {
    path: '/emailTemplate/create',
    perm: 'campaignEmail_manage',
    name: 'Thêm mới mẫu email',
    component: EmailTemplateForm
  },
  {
    path: '/emailTemplate/:id',
    perm: 'campaignEmail_manage',
    name: 'Chi tiết mẫu email',
    component: EmailTemplateForm
  },
  {
    path: '/campaignEmail',
    perm: 'campaignEmail_manage',
    name: 'Email Chăm sóc khách hàng',
    component: CampaignEmailList
  },
  {
    path: '/campaignEmail/:id',
    perm: 'campaignEmail_manage',
    name: 'Chi tiết email',
    component: CampaignEmailForm
  },
  //--------------------Else
  {
    path: '/audit',
    perm: 'audit_view',
    name: 'Lịch sử thay đổi dữ liệu',
    component: AuditList
  },
  {
    path: '/documentCompany',
    perm: 'documentCompany_manage',
    name: 'Quản lý tài liệu ',
    component: DocumentCompanyList
  },
  {
    path: '/documentCompanyView',
    name: 'Danh sách tài liệu công ty',
    component: UserViewDocumentCompanyList
  },
  {
    path: '/documentCompanyView/:id',
    name: 'Chi tiết tài liệu',
    component: UserViewDocumentCompanyView
  },
  {
    path: '/documentCompany/create',
    name: 'Thêm mới tài liệu công ty',
    component: DocumentCompanyForm
  },
  {
    path: '/documentCompany/:id',
    name: 'Sửa tài liệu công ty',
    component: DocumentCompanyForm
  },
  {
    path: '/feedBack',
    perm: 'feedBack_manage',
    name: 'Danh sách phản hồi',
    component: FeedBackList
  },
  {
    path: '/feedBack/create',
    name: 'Tạo mới phản hồi',
    component: FeedBackStore
  },
  {
    path: '/feedBack/:id',
    perm: 'feedBack_manage',
    name: 'Chi tiết phản hồi',
    component: FeedBackView
  },
  //-----------------------------
  {
    path: '/attribute',
    perm: 'category_manage',
    name: 'Quản lý danh mục',
    component: Attribute
  },
  {
    path: '/setting',
    name: 'Cài đặt',
    component: Settings
  },
  {
    path: '/maintainanceQuote',
    name: 'Mẫu báo giá',
    component: MaintenanceQuoteTemplate
  },
  {
    path: '/report',
    name: 'Báo cáo',
    component: Report
  },
  {
    path: '/target',
    name: 'Quản lý chỉ tiêu',
    component: Target
  },
  {
    path: '/taskUser',
    name: 'Quản lý công việc bảo hành,bảo trì',
    component: TaskUser
  },
  {
    path: '/banner',
    name: 'Quản lý banner',
    component: BannerList
  },
  {
    path: '/banner/create',
    name: 'Thêm mới banner',
    component: BannerForm
  },
  {
    path: '/banner/:id',
    name: 'Sửa banner',
    component: BannerForm
  }
];

export default routes;
