import React, { useMemo, useState, useEffect, Suspense } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import Nav from './Nav2';
import './Layout.css';
import MyBreadcrumbs from './Breadcrumbs';
import { useAuthContext } from '../../common/AuthProvider';
import routes from '../../routes/routes';
import AppLoading from './AppLoading';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Button, HTMLSelect, Icon, Navbar } from '@blueprintjs/core';
import NavItem from './NavItem';
import logo from './Logo_ATVIN.png';
import avatarUser from './avatar.png';
import logoSmall from './Logo-small.png';

function Layout() {
  const history = useHistory();
  const { perms, authorizedRoutes, me } = useAuthContext();
  const location = useLocation();

  //---------------------------breadcrumbs
  const [path, setPath] = useState('');
  useEffect(() => {
    if (location.pathname === '/') setPath('/');
  }, [location.pathname, path]);

  const crumbs = useMemo(() => {
    const destructPath = [
      '/',
      ...path
        .split('/')
        .slice(1)
        .map(path => '/' + path)
        .reduce((a, c) => [...a, a.length ? a[a.length - 1] + c : c], [])
    ];
    const crumbNames = routes
      .filter(route => destructPath.includes(route.path))
      .map(route => ({
        name: route.name
      }));
    const crumbPaths = [
      '/',
      ...location.pathname
        .split('/')
        .slice(1)
        .map(path => '/' + path)
        .reduce((a, c) => [...a, !a.length ? c : a[a.length - 1] + c], [])
    ];
    return crumbNames.map((crum, index) => ({
      name: crum.name,
      path: crumbPaths[index]
    }));
  }, [location.pathname, path]);

  function render(route) {
    return route.component ? (
      <Route
        key={route.name}
        path={route.path}
        exact={true}
        render={_ => {
          setPath(route.path);
          return <route.component />;
        }}
      />
    ) : null;
  }
  useEffect(() => {
    document.title = `Atvin - ${crumbs[crumbs.length - 1]?.name}`;
  });

  return (
    <div className="layout">
      {perms && (
        <div>
          <Nav crumbs={crumbs} small={true} />
          <div style={{ marginLeft: 60, minWidth: 600 }}>
            <MyBreadcrumbs crumbs={crumbs} />
            <h4 className="page-heading">{crumbs[crumbs.length - 1]?.name}</h4>
            <Container fluid>
              <Suspense fallback={<AppLoading />}>
                <Switch>
                  {authorizedRoutes.map(route => render(route))}
                  {authorizedRoutes.length > 0 ? <Redirect to="/" /> : null}
                </Switch>
              </Suspense>
            </Container>
          </div>
        </div>
      )}
      {!perms && (
        <div>
          <AppLoading />
        </div>
      )}
    </div>
  );
}

export default React.memo(Layout);
