import React, {
  useContext,
  useState,
  useMemo,
  Fragment,
  useCallback
} from 'react';
import DialogMui from './DialogMui';
import { Button } from '@blueprintjs/core';
import { useAPI } from '../common/hooks/api';
import { deletedToast, restoredToast } from './Toaster';

const ConfirmDialogContext = React.createContext();
export const useConfirmDialog = () => useContext(ConfirmDialogContext);

export const Provider = ({ children }) => {
  const api = useAPI();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState();
  const [action, setAction] = useState(() => () => null);

  const _delete = useCallback(({ text, endpoint, id, onSuccess, action }) => {
    setOpen(true);
    setText(text);
    const newAction = async () => {
      const res = await api.fetcher('delete', `${endpoint}/${id}`, {
        params: { action }
      });
      if (res) {
        onSuccess();
        setOpen(false);
        if (action !== 'restore') return deletedToast();
        restoredToast();
      }
    };
    setAction(() => newAction);
  }, []);

  const bulk = useCallback(({ text, endpoint, ids, onSuccess, action }) => {
    setOpen(true);
    setText(text);
    const newAction = async () => {
      const res = await api.fetcher('post', endpoint, {
        action,
        ids
      });
      if (res) {
        onSuccess();
        setOpen(false);
        if (action !== 'restore') return deletedToast();
        restoredToast();
      }
    };
    setAction(() => newAction);
  }, []);

  const softDelete = args => _delete({ ...args, action: 'delete' });
  const forceDelete = args => _delete({ ...args, action: 'force' });
  const restore = args => _delete({ ...args, action: 'restore' });

  const bulkSoftDelete = args => bulk({ ...args, action: 'delete' });
  const bulkForceDelete = args => bulk({ ...args, action: 'force' });
  const bulkRestore = args => bulk({ ...args, action: 'restore' });

  const value = useMemo(
    () => ({
      softDelete,
      forceDelete,
      restore,
      bulkSoftDelete,
      bulkForceDelete,
      bulkRestore
    }),
    []
  );

  return (
    <Fragment>
      <ConfirmDialogContext.Provider value={value}>
        {children}
      </ConfirmDialogContext.Provider>
      <DialogMui
        open={open}
        onClose={() => setOpen(false)}
        title={text}
        content={
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginBottom: 16
              }}
            >
              <Button
                loading={api.loading}
                intent="danger"
                text="Đồng ý"
                style={{ marginLeft: 10 }}
                onClick={action}
              />
              <Button text="Hủy" onClick={() => setOpen(false)} />
            </div>
          </div>
        }
      />
    </Fragment>
  );
};
