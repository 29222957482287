import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  Card,
  FormGroup,
  Intent,
  InputGroup,
  Button,
  Divider,
  Alignment
} from '@blueprintjs/core';

import { useAuthContext } from '../../common/AuthProvider';
import { useAPI } from '../../common/hooks/api';
import storage from '../../common/localStorage';
import Toaster from '../../shared/Toaster';
import './Login.css';
import googleIcon from './googleIcon.png';
import CarouselWrapper from './Carousel';
import { TextField } from '@material-ui/core';
import logoAtvin from './atvin_logo.png';
import CustomToaster from '../../shared/Toaster';

//-------------------------------------

const Login = React.memo(props => {
  const history = useHistory();

  const [url, setUrl] = useState();
  const [forgotPassword, setForgotPass] = useState();
  const { setAuth } = useAuthContext();
  const { fetcher, loading } = useAPI();
  const api = useAPI();

  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      emailOrPhone: yup
        .string()
        .trim()
        .required('Không được để trống'),
      password: yup.string().required('Không được để trống')
    }),
    onSubmit: async values => {
      const res = await fetcher('post', 'user/login', {
        emailOrPhone: values.emailOrPhone,
        password: values.password
      }).catch(e => {
        console.log({ e });
        if (e.status === 422) {
          CustomToaster.show({
            message: 'Sai thông tin đăng nhập',
            icon: 'small-cross',
            intent: 'danger'
          });
        } else if (e.status === 400) {
          formik.setFieldError('emailOrPhone', e.data.emailOrPhone);
        }
      });
      if (res && res.access_token) {
        storage.saveAccessToken(res.access_token);
        setAuth(res.access_token);
        history.push('/');
      }
      console.log('res', res);
      if (res && res.errors) {
        formik.setErrors(res.errors);
      }
      if (res && res.error) {
        // showToast(res.error);
      }
    }
  });

  async function signInWithGoogle() {
    const { url } = await api.fetcher('get', 'google/url');
    setUrl({ googleLoginUrl: url });
  }
  function forgetPassword() {
    window.location.href = '/forgotPass';
  }

  useEffect(() => {
    if (url) {
      window.location.href = url.googleLoginUrl;
    }
  }, [url]);

  const showToast = message => {
    Toaster.show({ message });
  };
  console.log('err', formik.errors);
  return (
    <div>
      <div className="body">
        <CarouselWrapper />
        <form className="form" onSubmit={formik.handleSubmit}>
          {/* <img className="logo" src="./atvin_logo.png" /> */}
          <Card className="card" elevation={4}>
            <img className="logo" src={logoAtvin} />
            <div style={{ marginTop: 30 }}>
              <FormGroup>
                <TextField
                  id={'standard-basic'}
                  label={'Địa chỉ email / Số điện thoại '}
                  onChange={formik.handleChange('emailOrPhone')}
                  value={formik.values.emailOrPhone}
                  style={{ width: '100%' }}
                  color={'success'}
                  error={formik?.errors?.emailOrPhone}
                />
                <div
                  style={{
                    height: '20px',
                    width: '100%',
                    color: 'red',
                    fontSize: '14px'
                  }}
                >
                  {formik.errors.emailOrPhone}
                </div>
                {/*</div>*/}
              </FormGroup>
              <FormGroup>
                <TextField
                  id={'standard-basic'}
                  label={'Mật khẩu'}
                  onChange={formik.handleChange('password')}
                  value={formik.values.password}
                  type={'password'}
                  style={{ width: '100%' }}
                  color={'success'}
                />
                <div
                  style={{
                    height: '20px',
                    width: '100%',
                    color: 'red',
                    fontSize: '14px'
                  }}
                >
                  {formik.errors.password}
                </div>
              </FormGroup>
            </div>
            <button className="btn-1" type="submit" style={{ marginTop: 15 }}>
              Đăng nhập
            </button>
            <button className="btn-2" onClick={signInWithGoogle}>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div>
                  <img
                    src={'./google_logo.png'}
                    height={24}
                    width={24}
                    style={{ marginRight: 10 }}
                  />
                  <span>Đăng nhập bằng Google</span>
                </div>
              </div>
            </button>
            {/*<Button*/}
            {/*  className={'btn-1'}*/}
            {/*  text="Đăng nhập"*/}
            {/*  alignText={Alignment.CENTER}*/}
            {/*  large={true}*/}
            {/*  fill={true}*/}
            {/*  intent={Intent.PRIMARY}*/}
            {/*  loading={loading}*/}
            {/*  type="submit"*/}
            {/*/>*/}
            {/*<Button*/}
            {/*  className={'btn-2'}*/}
            {/*  type="button"*/}
            {/*  alignText="center"*/}
            {/*  fill={true}*/}
            {/*  large={true}*/}
            {/*  onClick={signInWithGoogle}*/}
            {/*>*/}
            {/*  <div style={{ display: 'flex', justifyContent: 'space-around' }}>*/}
            {/*    <div>*/}
            {/*      <img*/}
            {/*        src="./google_logo.png"*/}
            {/*        height={24}*/}
            {/*        width={24}*/}
            {/*        style={{ marginRight: 10 }}*/}
            {/*      />*/}
            {/*      <span>Đăng nhập bằng Google</span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</Button>*/}
            <a
              onClick={forgetPassword}
              style={{
                color: '#0362fc',
                textAlign: 'center',
                marginBottom: 20
              }}
            >
              Quên mật khẩu ?
            </a>
          </Card>
        </form>
      </div>
    </div>
  );
});

export default Login;
