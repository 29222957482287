import React, { Fragment, useState } from 'react';
import { Input } from './Forms';
import { Row, Col } from 'react-bootstrap';

export default React.memo(({ values, onChange, label, error, ...other }) => {
  return (
    <Fragment>
      <Row>
        <Col xs={12} sm={4}>
          <Input
            label="Tỉnh/Thành phố"
            type="query-select"
            labelField="name"
            endpoint="region?type=1"
            valueField="id"
            value={values.province_id}
            onChange={value =>
              onChange({
                province_id: value,
                district_id: null,
                ward_id: null,
                address_detail: values.address_detail
              })
            }
            {...other}
          />
        </Col>
        <Col xs={12} sm={4}>
          <Input
            label="Quận/Huyện"
            type="query-select"
            labelField="name"
            endpoint={
              values.province_id && 'region?parent_id=' + values.province_id
            }
            valueField="id"
            value={values.district_id}
            onChange={value =>
              onChange({
                ...values,
                district_id: value,
                ward_id: null
              })
            }
            disabled={!values.province_id}
            {...other}
          />
        </Col>
        <Col xs={12} sm={4}>
          <Input
            label="Phường/Xã"
            type="query-select"
            labelField="name"
            endpoint={
              values.district_id && 'region?parent_id=' + values.district_id
            }
            valueField="id"
            value={values.ward_id}
            onChange={value =>
              onChange({
                ...values,
                ward_id: value
              })
            }
            disabled={!values.district_id}
            {...other}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Input
            label="Địa chỉ cụ thể"
            type="textarea"
            rows={2}
            value={values.address_detail}
            onChange={val => onChange({ ...values, address_detail: val })}
            error={error}
          />
        </Col>
      </Row>
    </Fragment>
  );
});
