import React, {
  Fragment,
  useEffect,
  useMemo,
  useContext,
  useState
} from 'react';
import { useFormik } from 'formik';
import { Button, Divider, Intent, Icon } from '@blueprintjs/core';
import { useAPI, useFetch } from '../../common/hooks/api';
import { useAuthContext } from '../../common/AuthProvider';
import { TextField } from '@material-ui/core';
//-------------------------------------

const ChangePassword = React.memo(({ data, onClose }) => {
  const api = useAPI();
  const auth = useAuthContext();
  const [show, setShow] = useState([false, false, false]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { setFieldError }) => {
      if (values.passwordConfirm !== values.password) {
        formik.setFieldError('passwordConfirm', 'Mật khẩu không trùng khớp');
      } else {
        try {
          let res;
          res = await api.fetcher('post', 'change-password', values);
          if (res) {
            onClose();
          }
        } catch (e) {
          if (e.data?.errors) {
            Object.keys(e.data.errors).forEach(field => {
              setFieldError(field, e.data.errors[field][0]);
            });
          }
        }
      }
    }
  });

  const inputs = useMemo(
    () => [
      [
        {
          label: 'Mật khẩu cũ',
          field: 'old_password',
          type: show[0] ? 'text' : 'password',
          value: formik.values.old_password,
          error: formik.errors.old_password,
          onChange: formik.handleChange('old_password'),
          grid: { xs: 12, sm: 12, md: 12 }
        },
        {
          label: 'Mật khẩu mới',
          field: 'password',
          type: show[1] ? 'text' : 'password',
          value: formik.values.password,
          error: formik.errors.password,
          onChange: formik.handleChange('password'),
          grid: { xs: 12, sm: 12, md: 12 }
        }
      ]
    ],
    [formik]
  );

  return (
    <Fragment>
      <div>
        <div style={{ margin: 20 }}>
          <div className={'row'} style={{ marginBottom: 10 }}>
            <span style={{ width: '90%', height: 50 }}>
              <TextField
                fullWidth={true}
                label={'Mật khẩu cũ'}
                type={show[0] ? 'text' : 'password'}
                value={formik.values.old_password}
                onChange={e =>
                  formik.setFieldValue('old_password', e.target.value)
                }
              />
            </span>
            <span style={{ width: '10%', marginTop: 18, textAlign: 'center' }}>
              {show[0] === false ? (
                <Icon
                  iconSize={20}
                  icon={'eye-open'}
                  onClick={() => {
                    setShow(
                      show.map((form, idx) => (idx === 0 ? !form : form))
                    );
                  }}
                />
              ) : (
                <Icon
                  iconSize={20}
                  icon={'eye-off'}
                  onClick={() => {
                    setShow(
                      show.map((form, idx) => (idx === 0 ? !form : form))
                    );
                  }}
                />
              )}
            </span>
          </div>
          {formik.errors.old_password !== undefined && (
            <span style={{ color: '#f44336', fontSize: '0.75rem' }}>
              {formik.errors.old_password}
            </span>
          )}
        </div>
        <div style={{ margin: 20 }}>
          <div className={'row'} style={{ marginBottom: 10 }}>
            <span style={{ width: '90%', height: 50 }}>
              <TextField
                fullWidth={true}
                label={'Mật khẩu mới'}
                type={show[1] ? 'text' : 'password'}
                value={formik.values.password}
                onChange={e => formik.setFieldValue('password', e.target.value)}
              />
            </span>
            <span style={{ width: '10%', marginTop: 18, textAlign: 'center' }}>
              {show[1] === false ? (
                <Icon
                  iconSize={20}
                  icon={'eye-open'}
                  onClick={() => {
                    setShow(
                      show.map((form, idx) => (idx === 1 ? !form : form))
                    );
                  }}
                />
              ) : (
                <Icon
                  iconSize={20}
                  icon={'eye-off'}
                  onClick={() => {
                    setShow(
                      show.map((form, idx) => (idx === 1 ? !form : form))
                    );
                  }}
                />
              )}
            </span>
          </div>
          {formik.errors.password !== undefined && (
            <span style={{ color: '#f44336', fontSize: '0.75rem' }}>
              {formik.errors.password}
            </span>
          )}
        </div>
        <div style={{ margin: 20 }}>
          <div className={'row'} style={{ marginBottom: 10 }}>
            <span style={{ width: '90%', height: 50 }}>
              <TextField
                fullWidth={true}
                label={'Xác nhận mật khẩu mới'}
                type={show[2] ? 'text' : 'password'}
                value={formik.values.passwordConfirm}
                onChange={e =>
                  formik.setFieldValue('passwordConfirm', e.target.value)
                }
              />
            </span>
            <span style={{ width: '10%', marginTop: 18, textAlign: 'center' }}>
              {show[2] === false ? (
                <Icon
                  iconSize={20}
                  icon={'eye-open'}
                  onClick={() => {
                    setShow(
                      show.map((form, idx) => (idx === 2 ? !form : form))
                    );
                  }}
                />
              ) : (
                <Icon
                  iconSize={20}
                  icon={'eye-off'}
                  onClick={() => {
                    setShow(
                      show.map((form, idx) => (idx === 2 ? !form : form))
                    );
                  }}
                />
              )}
            </span>
          </div>
          {formik.errors.passwordConfirm !== undefined && (
            <span style={{ color: '#f44336', fontSize: '0.75rem' }}>
              {formik.errors.passwordConfirm}
            </span>
          )}
        </div>
        <div class="dialog-bottom-actions">
          <Button text="Huỷ" disabled={formik.isSubmitting} onClick={onClose} />
          <Button
            text={'Cập nhật'}
            intent={Intent.PRIMARY}
            loading={formik.isSubmitting}
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
    </Fragment>
  );
});

export default ChangePassword;
