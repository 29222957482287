import React from 'react';
import { useDialog } from '../../shared/Dialog';
import Container from './Container';

export default () => {
  const dialog = useDialog();
  return () =>
    dialog.show({
      dialogProps: {
        icon: 'notifications',
        title: 'Thông báo',
        style: {
          height: '80%'
        },
        bodyStyle: {
          padding: 0,
          margin: 0
        }
      },
      body: (
        <Container
          onClose={() => {
            dialog.close();
          }}
        />
      )
    });
};
