import React, { useState, Fragment } from 'react';
import { Button, Switch } from '@blueprintjs/core';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import Popover from '../Popover';
import './FieldMenu.css';
import { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const FieldMenu = ({ label, checker }) => {
  label = label || 'label';
  const { key, checks, check, reset, data } = checker;

  const groups = useMemo(() => {
    return data.reduce((result, att) => {
      const index = result.findIndex(group => group.id === att.group_id);
      if (index === -1) {
        const newGroup = { ...att.group };
        newGroup.children = [att];
        return [...result, newGroup];
      }
      let group = result[index];
      group.children = [...group.children, att];
      return result;
    }, []);
  }, [data]);

  const [tab, setTab] = useState(0);

  const classes = useStyles();
  return (
    <div>
      <div className="field-wrapper">
        <div className="field-tabs">
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            orientation="vertical"
            variant="scrollable"
            value={tab}
            onChange={(_, tab) => setTab(tab)}
            className={classes.tabs}
          >
            {groups.map(group => (
              <Tab
                key={group.name || 0}
                label={group.name || 'Thông tin cơ bản'}
              />
            ))}
          </Tabs>
        </div>
        <div className="field-filter">
          {groups[tab].children.map((field, i) => (
            <div key={i} className="field-checkbox">
              <Switch
                onChange={() => check(field[key])}
                checked={checks[field[key]]}
              />
              <span className="field-text" onClick={() => check(field[key])}>
                {field[label]}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="filter-footer">
        <Button
          icon="reset"
          text="Đặt lại"
          minimal
          intent="primary"
          onClick={() => reset()}
        />
      </div>
    </div>
  );
};

export default React.memo(props => {
  return (
    <Fragment>
      <Popover
        content={<FieldMenu checker={props.checker} label={props.label} />}
      >
        <Button icon="list-columns" />
      </Popover>
    </Fragment>
  );
});
