import React, { useCallback } from 'react';
import MultiSelect from './MultiSelect';
import { FormHelperText } from '@material-ui/core';

const smallText = { marginTop: 0, color: '#636363', fontSize: 15 };

export default function SelectMultiPerson(props) {
  const { value, type, error, ...otherProps } = props;

  const renderOption = useCallback(option => {
    const { name, email, phone } = option;
    return (
      <div style={{ padding: 5 }}>
        <p>{name}</p>
        <span style={smallText}>{email}</span>
        <p style={smallText}>{phone}</p>
      </div>
    );
  }, []);

  return (
    <div>
      <MultiSelect
        {...props}
        labelField="name"
        valueField="id"
        renderOption={renderOption}
        error={error}
      />
    </div>
  );
}
