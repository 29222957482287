import { useState, useEffect } from 'react';

//--------------------------helpers
const getCheckedItems = (data, checks, key) =>
  data.filter(item => checks[item[key]]);

const mapCheck = (data, checks, key, forceState) => {
  //----sync checking status with new data
  const _checks = data?.reduce(
    (a, c) => ({
      ...a,
      _all: checks?._all,
      _indeterminate: checks?._indeterminate,
      [c[key]]:
        forceState !== undefined
          ? forceState
          : c[key] in checks
          ? checks?.[c[key]]
          : c.checked || false
    }),
    {}
  );

  return { ...checks, ..._checks };
};

const checkIndeterminate = (data, checks, key) => {
  //for current dataset only
  let indeterminate = false;
  let lastItem = null;
  data.forEach(item => {
    if (lastItem !== null && checks[item[key]] !== lastItem) {
      indeterminate = true;
    }
    lastItem = checks[item[key]];
  });
  checks._all = indeterminate ? false : lastItem;
  checks._indeterminate = indeterminate;
  return checks;
};

//----------------------------MAIN
export default function useCheck(
  data = [],
  key,
  onChange = () => null,
  init = {}
) {
  //--------------------------state
  const [checks, setCheck] = useState(init);
  const [checkedItems, setCheckedItems] = useState([]);

  //persist data
  const [persistData, setPersistData] = useState(data);

  const setState = checks => {
    setCheck(checkIndeterminate(data, checks, key));
    const _checkedItems = getCheckedItems(persistData, checks, key);
    setCheckedItems(_checkedItems);
    onChange({ checkedItems: _checkedItems, checks });
  };

  //--------------------------effects
  useEffect(() => {
    if (data.length > 0) {
      const filtered = data.filter(
        item => !persistData.find(psItem => psItem[key] === item[key])
      );
      setPersistData([...persistData, ...filtered]);
    }
  }, [data]);

  useEffect(() => {
    if (data.length > 0) {
      const _checks = mapCheck(data, checks, key);
      setState(_checks);
    }
  }, [persistData]);

  //--------->------------------setters
  const check = keyValue => {
    const _checks = { ...checks, [keyValue]: !checks?.[keyValue] };
    setState(_checks);
  };

  const checkAll = () => {
    const _checks = mapCheck(data, checks, key, !checks._all);
    setState(_checks);
  };

  const unCheckAll = () => {
    const _checks = mapCheck(data, checks, key, false);
    setState(_checks);
  };

  const reset = () => setState(mapCheck(data, {}, key));

  return {
    key,
    checks,
    setState,
    check,
    checkAll,
    unCheckAll,
    reset,
    checkedItems,
    data,
    persistData
  };
}
