import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout from '../views/Layout/Layout';
import Login from '../views/Login/Login';
import GoogleLogin from '../views/Login/GoogleLogin';
import ForgotPassword from '../views/Login/ForgotPassword';
import ResetPassword from '../views/Login/ResetPassword';
import Error from '../views/Error/Error';
import Error401 from '../views/Error/Error401';
import { useAuthContext } from '../common/AuthProvider';

function MainRouter() {
  const { auth } = useAuthContext();
  return (
    <Switch>
      <Route path="/error" name="Error" component={Error} />
      <Route path="/error-401" name="Unauthenticated" component={Error401} />
      <Route path="/google" name="GoogleLogin" component={GoogleLogin} />
      <Route
        path="/forgotPass"
        name="ForgotPassword"
        component={ForgotPassword}
      />
      <Route path="/resetPass" name="ResetPassword" component={ResetPassword} />
      <Route path="/" name="Home" component={auth ? Layout : Login} />
    </Switch>
  );
}

export default MainRouter;
