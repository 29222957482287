import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Card,
  Button,
  Alert,
  FormGroup,
  Intent,
  InputGroup,
  Alignment
} from '@blueprintjs/core';
import { useAPI, useFetch } from '../../common/hooks/api';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import CarouselWrapper from './Carousel';

function ForgotPassword() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .trim()
        .required('không để trống')
        .email('Địa chỉ email không hợp lệ')
    }),
    onSubmit: async values => {
      const res = await fetcher('post', 'password/create', {
        email: values.email
      })
        .then(() => setOpen(true))
        .catch(e => {
          formik.setErrors(e.data.errors);
        });
      if (res && res.errors) {
        formik.setErrors(res.errors);
      }
      if (res && res.error) {
        // showToast(res.error);
      }
    }
  });
  //React-router

  const { fetcher, loading } = useAPI();

  function handleClose() {
    setOpen(false);
  }

  function login() {
    window.location.href = history.push('/');
  }

  return (
    <div>
      <div className="body">
        <CarouselWrapper />
        <form className="form" onSubmit={formik.handleSubmit}>
          <Card className="card" elevation={4}>
            <img className="logo" src="/atvin_logo.png" />
            <Alert
              confirmButtonText="Ok"
              isOpen={open}
              onClose={() => handleClose()}
              onConfirm={() => login()}
            >
              <p>Vui lòng kiểm tra email của bạn</p>
            </Alert>
            <FormGroup
              label="Địa chỉ email"
              helperText={formik.errors.email && formik.errors.email}
              intent={formik.errors.email && Intent.DANGER}
            >
              <InputGroup
                type="input"
                large={true}
                value={formik.values.email}
                onChange={formik.handleChange('email')}
                onBlur={formik.handleBlur('email')}
                intent={formik.errors.email && Intent.DANGER}
              />
            </FormGroup>
            <Button
              text="Xác nhận"
              alignText={Alignment.CENTER}
              large={true}
              fill={true}
              intent={Intent.PRIMARY}
              loading={loading}
              type="submit"
            />
          </Card>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
