import React, { useState, useEffect, useMemo, Fragment } from 'react';

import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { Container, Col, Row } from 'react-bootstrap';
import { Card, Button, Intent, Divider, ButtonGroup } from '@blueprintjs/core';

import { useAPI, useFetch } from '../../common/hooks/api';
import Forms from '../../shared/Forms/Forms';
import ScrollButton from '../../shared/ScrollButton';
import { useAuthContext } from '../../common/AuthProvider';
import { mutate } from 'swr';

//-------------------------------------

function UserUpdate({ data, onClose }) {
  const { pathname } = useLocation();
  const api = useAPI();
  const { permNames } = useAuthContext();
  const readOnly = !permNames.includes('user_manage');
  const [action, setAction] = useState({
    mode: data?.id ? 'update' : 'create',
    editing: true
  });

  const formik = useFormik({
    initialValues: { ...data, role_id: data?.roles?.[0]?.id },
    onSubmit: async (values, { setFieldError }) => {
      let formData = {
        ...values,
        role_name: values.role_name ? values.role_name.id : null
      };
      try {
        let res;
        if (action.mode === 'create') {
          res = await api.fetcher('post', 'user', formData);
        } else {
          res = await api.fetcher('put', `user/${data?.id}`, formData);
        }
        if (res) {
          mutate(['get', pathname.slice(1)]);
          onClose();
        }
      } catch (e) {
        if (e.data?.errors) {
          Object.keys(e.data.errors).forEach(field => {
            setFieldError(field, e.data.errors[field][0]);
          });
        }
      }
    }
  });

  const fetcher = useFetch(data?.id && ['get', 'user/' + data.id], {
    onSuccess: data => {
      formik.setValues({ ...data, role_id: data.roles[0]?.id });
    }
  });
  console.log('formik', formik.values);
  const inputs = useMemo(
    () => [
      [
        {
          label: 'Tên nhân viên',
          type: 'text',
          field: 'name',
          grid: { xs: 12, sm: 12, md: 12 }
        },
        {
          label: 'Số điện thoại',
          type: 'text',
          field: 'phone',
          grid: { xs: 12, sm: 12, md: 12 }
        },
        {
          label: 'Địa chỉ email',
          field: 'email',
          type: 'text',
          grid: { xs: 12, sm: 12, md: 12 }
        },
        action.mode === 'update' &&
          action.editing === true && {
            label: 'Mật khẩu',
            type: 'password',
            field: 'password',
            onChange: value =>
              formik.setFieldValue('password', value ? value : undefined),
            grid: { xs: 12, sm: 12, md: 12 }
          },
        action.mode === 'create' && {
          label: 'Mật khẩu',
          type: 'password',
          value: formik.values?.password,
          error: formik.errors?.password,
          onChange: value =>
            formik.setFieldValue('password', value ? value : undefined),
          grid: { xs: 12, sm: 12, md: 12 }
        },
        {
          label: 'Nhóm người dùng',
          field: 'role_id',
          endpoint: 'role',
          valueField: 'id',
          labelField: 'display_name',
          type: 'query-select',
          readOnlyValue: formik.values.roles?.[0]?.name,
          grid: { xs: 12, sm: 12, md: 12 }
        }
      ]
    ],
    [formik]
  );

  return (
    <Fragment>
      <Forms
        variant="standard"
        inputs={inputs}
        data={formik.values}
        onChange={formik.setFieldValue}
        readOnly={readOnly}
        errors={api.error}
      />
      <Row>
        <Col>
          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            {action.mode === 'create' && (
              <Button
                text="Thêm mới"
                onClick={formik.handleSubmit}
                intent={Intent.PRIMARY}
                loading={api.loading}
              />
            )}
            {action.mode === 'update' && action.editing === true && (
              <Fragment>
                {permNames.includes('user_manage') && (
                  <ButtonGroup>
                    <Button
                      style={{ marginRight: 10 }}
                      text="Huỷ"
                      onClick={() => {
                        setAction({ ...action, editing: false });
                        formik.setValues({
                          ...fetcher.data,
                          role_name: fetcher.data.roles[0]
                        });
                        onClose();
                      }}
                    />
                    <Button
                      text="Cập nhật"
                      onClick={formik.handleSubmit}
                      intent={Intent.PRIMARY}
                      loading={api.loading}
                    />
                  </ButtonGroup>
                )}
              </Fragment>
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

export default UserUpdate;
