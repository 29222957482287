export const inputTypes = {
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number',
  TEXTAREA: 'textarea',
  SWITCH: 'switch',
  SELECT: 'select',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  DATETIME: 'datetime',
  QUERY_SELECT: 'query-select'
};

export const attributeCategories = {
  CUSTOMER: 0,
  CONSTRUCTION: 1,
  CONTRACT: 2,
  TASK: 3
};
export const displayDefault = {
  noneDisplay: 0,
  display: 1
};

export const attributeTypes = {
  TEXT: 'text',
  STRING: 'string', // TODO: replaced by TEXT
  SELECT: 'select', // TODO: deprecated
  DATETIME: 'datetime',
  LIST: 'list'
};
