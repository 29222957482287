import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './common/AuthProvider';
import { Provider as DialogProvider } from './shared/Dialog';
import MainRouter from './routes/MainRouter';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/vi';
import moment from 'moment';
import { Provider as ConfirmProvider } from './shared/ConfirmDialog';

function App() {
  return (
    <BrowserRouter>
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={moment}
        locale={'vi'}
      >
        <ConfirmProvider>
          <AuthProvider>
            <DialogProvider>
              <MainRouter />
            </DialogProvider>
          </AuthProvider>
        </ConfirmProvider>
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  );
}

export default App;
