import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Toaster from '../../shared/Toaster';
import { useAuthContext } from '../../common/AuthProvider';
import local_storage from '../../common/localStorage';
import { useAPI } from '../../common/hooks/api';
import { Intent } from '@blueprintjs/core';

function GoogleLogin() {
  const { setAuth } = useAuthContext();
  const location = useLocation();
  const history = useHistory();
  const { fetcher } = useAPI();

  const showToast = message => {
    Toaster.show({ message, intent: Intent.DANGER });
  };
  useEffect(() => {
    fetcher('get', `google/callback${location.search}`)
      .then(data => {
        local_storage.saveAccessToken(data.accessToken);
        setAuth(data.accessToken);
      })
      .then(() => history.push('/'))
      .catch(e => {
        if (e.status == 401) {
          showToast(`Người dùng không tồn tại!`);
        }
      });
  }, [fetcher, history, location.search, setAuth]);

  return <div></div>;
}

export default GoogleLogin;
