import React, { createContext, useMemo, useContext, useState } from 'react';

import local_storage from './localStorage';
import routes from '../routes/routes';
import { useFetch } from './hooks/api';

const AuthContext = createContext(null);

function AuthProvider(props) {
  const storedToken = local_storage.getAccessToken();
  const [auth, setAuth] = useState(storedToken ? storedToken : null);

  const { data: perms } = useFetch(auth ? ['get', 'permission'] : null);
  const { data: me, revalidate: revalidateMe } = useFetch(
    auth ? ['post', 'me'] : null
  );

  const permNames = useMemo(() => {
    return perms?.map(perm => perm.name);
  }, [perms]);

  const authorizedRoutes = useMemo(() => {
    if (perms) {
      const checkPerm = route => {
        if (!route.perm) return true;
        if (Array.isArray(route.perm)) {
          return route.perm.reduce(
            (result, perm) => permNames.indexOf(perm) > -1 || result,
            false
          );
        }
        if (permNames.indexOf(route.perm) > -1) return true;
        return false;
      };

      return routes.filter(checkPerm);
    }
    return [];
  }, [perms]);

  const noties = useFetch(auth ? ['get', 'notification'] : null);

  const authValue = {
    me: me?.data,
    revalidateMe,
    auth,
    setAuth,
    perms,
    permNames,
    authorizedRoutes,
    noties
  };

  return <AuthContext.Provider value={authValue} {...props} />;
}
export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
