import React from 'react';
import { Dialog, Button, Classes, Divider, Intent } from '@blueprintjs/core';
import './Dialog.css';
//-------------------------------------

const CustomDialog = React.memo(
  ({ isOpen, decision, onClose, dialogProps, body }) => {
    return (
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        canEscapeKeyClose={true}
        {...dialogProps}
      >
        <div className="custom-dialog-body" style={dialogProps.bodyStyle}>
          {body || ''}
        </div>
        {!body && (
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="Huỷ" onClick={() => decision.resolve(false)} />
              <Divider />
              <Button
                text="Đồng ý"
                intent={Intent.DANGER}
                onClick={() => decision.resolve(true)}
              />
            </div>
          </div>
        )}
      </Dialog>
    );
  }
);

export default CustomDialog;
