import React, { useState, useEffect, useMemo, Fragment } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Container, Col, Row } from 'react-bootstrap';
import { Card, Button, Intent, Divider, ButtonGroup } from '@blueprintjs/core';

import { useAPI, useFetch } from '../../common/hooks/api';
import { mapResData } from '../../common/utils';
import Forms from '../../shared/Forms/Forms';
import ScrollButton from '../../shared/ScrollButton';
import { Select, InputLabel, MenuItem, TextField } from '@material-ui/core';
import { useAuthContext } from '../../common/AuthProvider';

//-------------------------------------

function ChangeInfo({ onClose }) {
  const { revalidateMe } = useAuthContext();
  const history = useHistory();
  const params = useParams();
  const [role, setRole] = useState();
  const [dataId, setDataId] = useState();
  const api = useAPI();
  const [readOnly, setReadOnly] = useState(true);
  const [action, setAction] = useState({
    mode: 'update',
    editing: false
  });
  const fetcher2 = useFetch(['get', 'infoUser'], {
    onSuccess: data => {
      formik.setValues({ ...data.user });
      setDataId({ ...data.user });
    }
  });

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { setFieldError }) => {
      try {
        let res;
        res = await api.fetcher('put', 'user/' + dataId.id, values);
        if (res) {
          onClose();
          revalidateMe();
        }
      } catch (e) {
        if (e.data?.errors) {
          Object.keys(e.data.errors).forEach(field => {
            setFieldError(field, e.data.errors[field][0]);
          });
        }
      }
    }
  });
  const fetcher = useFetch(['get', 'infoUser'], {
    onSuccess: data => {
      setDataId(data);
    }
  });
  useEffect(() => {
    if (dataId !== undefined) {
      if (dataId.roles.length > 0) {
        formik.setFieldValue('role_name', dataId.roles[0].id);
      }
    }
  }, [dataId]);
  const formLoading = false;
  const inputs = useMemo(
    () => [
      [
        {
          label: 'Tên',
          field: 'name',
          type: 'text',
          grid: { xs: 12, sm: 12, md: 12 }
        },
        {
          label: 'Số điện thoại',
          field: 'phone',
          type: 'number',
          grid: { xs: 12, sm: 12, md: 12 }
        },
        {
          label: 'Địa chỉ email',
          field: 'email',
          type: 'text',
          grid: { xs: 12, sm: 12, md: 12 }
        }
      ]
    ],
    [formik]
  );

  return (
    <Container>
      <Forms
        inputs={inputs}
        loading={formLoading}
        readOnly={readOnly}
        data={formik.values}
        onChange={formik.setFieldValue}
        errors={api.error}
        variant="standard"
      />
      <Row>
        <Col>
          <ScrollButton
            component={() => (
              <div style={{ textAlign: 'right' }}>
                {action.mode === 'create' && (
                  <Button
                    text="Thêm mới"
                    onClick={formik.handleSubmit}
                    intent={Intent.PRIMARY}
                    loading={api.loading}
                  />
                )}
                {action.mode === 'update' && action.editing === true && (
                  <Fragment>
                    <ButtonGroup>
                      <Button
                        text="Huỷ"
                        onClick={() => {
                          setAction({ ...action, editing: false });
                          setReadOnly(true);
                          onClose();
                        }}
                      />
                      <Divider />
                      <Button
                        text="Cập nhật"
                        intent={Intent.PRIMARY}
                        loading={api.loading}
                        onClick={formik.handleSubmit}
                      />
                    </ButtonGroup>
                  </Fragment>
                )}
                {action.mode === 'update' && action.editing === false && (
                  <Button
                    text="Cập nhật thông tin"
                    intent={Intent.PRIMARY}
                    onClick={() => {
                      setAction({ ...action, editing: true });
                      setReadOnly(false);
                    }}
                  />
                )}
              </div>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ChangeInfo;
