import React, { useMemo, useState } from 'react';
import { Button, Navbar } from '@blueprintjs/core';

import NavItem from './NavItem';
import { useAuthContext } from '../../common/AuthProvider';
import { useHistory } from 'react-router-dom';
import avatarUser from './avatar.png';
import logo from './Logo-small.png';
import NavTree from './NavTree';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import ChangeInfo from '../ChangeInfo/ChangeInfo';
import LowPriorityOutlinedIcon from '@material-ui/icons/LowPriorityOutlined';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { People } from '@material-ui/icons';
import './Layout.css';
import useNotificationDialog from '../Notification/useNotificationDialog';

function Nav({ crumbs, small }) {
  const history = useHistory();
  const { authorizedRoutes, me } = useAuthContext();
  const authPaths = authorizedRoutes.map(route => route.path);
  const showNoti = useNotificationDialog();

  function checkPerm(route) {
    let newRoute = { ...route };
    if (newRoute.child) {
      newRoute.child = newRoute.child
        .map(child => checkPerm(child))
        .filter(child => child);
    }
    return !newRoute.path
      ? newRoute
      : authPaths.indexOf(newRoute.path) > -1
      ? newRoute
      : null;
  }

  const filtered = leftItems
    .map(route => checkPerm(route))
    .filter(route => route)
    .filter(route => (route.child ? route.child.length > 0 : true));
  const noti = useMemo(
    () => [
      {
        label: 'Thông báo',
        Icon: NotificationsOutlinedIcon,
        path: showNoti
      }
    ],
    []
  );

  const userMenu = useMemo(
    () => [
      {
        label: me?.name,
        Icon: AccountCircleOutlinedIcon,
        child: [
          {
            label: 'Thay đổi thông tin',
            path: '/changeInfo'
          },
          {
            label: 'Đổi mật khẩu',
            path: '/changePassWord'
          },
          {
            label: 'Đăng xuất',
            path: '/logout'
          }
        ]
      }
    ],
    [me]
  );
  const [hover, setHover] = useState(false);
  const [expand, setExpand] = useState(() =>
    [...filtered, 'last'].map(item => false)
  );
  return (
    <div
      className="menu-bar"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => {
        setExpand(expand.map(() => false));
        setHover();
      }}
      id={'menuBar'}
      style={{ overflowY: 'auto' }}
    >
      <div id="elip1" />
      <div
        style={{
          display: 'flex',
          marginBottom: 100
        }}
        onClick={() => history.push('/')}
      >
        <img
          className="atvin-logo"
          src="/atvin_logo_only.png"
          height={50}
          alt="logo"
        />
        <img
          className="atvin-logo-text"
          src="/atvin_text_only.png"
          height={50}
          alt="logo-text"
        />
      </div>
      <NavTree
        hover={hover}
        items={filtered}
        expand={expand}
        setExpand={setExpand}
      />
      <div style={{ marginTop: 'auto' }}>
        <NavTree
          hover={hover}
          items={noti}
          // expand={[expand[expand.length - 1]]}
          // setExpand={() =>
          //   setExpand(
          //     expand.map((item, i) => (i === expand.length - 1 ? !item : false))
          //   )
          // }
        />
        <NavTree
          hover={hover}
          items={userMenu}
          expand={[expand[expand.length - 1]]}
          setExpand={() =>
            setExpand(
              expand.map((item, i) => (i === expand.length - 1 ? !item : false))
            )
          }
        />
      </div>
    </div>
  );
}

const leftItems = [
  {
    label: 'Trang chủ',
    Icon: HomeOutlinedIcon,
    path: '/'
  },
  {
    label: 'Khách Hàng',
    Icon: PersonOutlineIcon,
    child: [
      { label: 'Khách hàng của Atvin', path: '/customerAtvin' },
      { label: 'Khách hàng tiềm năng', path: '/customerPotential' },
      { label: 'Thông tin công trình', path: '/construction' },
      { label: 'SMS CSKH', path: '/campaignSms' },
      { label: 'Email CSKH', path: '/campaignEmail' },
      { label: 'Phản hồi của khách hàng', path: '/feedBack' },
      { label: 'Mẫu email', path: '/emailTemplate' }
    ]
  },
  {
    label: 'Hợp Đồng',
    Icon: AssignmentOutlinedIcon,
    path: '/contract'
  },
  {
    label: 'Công Việc',
    Icon: WorkOutlineOutlinedIcon,
    child: [
      { label: 'Công việc nội bộ', path: '/taskInternal' },
      { label: 'Công việc giám sát', path: '/taskPublic' },
      {
        label: 'Quản lý công việc bảo hành,bảo trì',
        path: '/taskUser'
      }
    ]
  },
  {
    label: 'Báo cáo',
    Icon: LowPriorityOutlinedIcon,
    path: '/report'
  },
  {
    label: 'Quản lý nhân sự',
    Icon: People,
    child: [
      {
        label: 'Nhân viên',
        path: '/user'
      },

      {
        label: 'Quản lý chỉ tiêu',
        path: '/target'
      },
      {
        label: 'Lịch sử chấm công',
        path: '/timekeeper'
      }
    ]
  },
  {
    label: 'Tài liệu',
    Icon: DescriptionIcon,
    child: [
      {
        label: 'Tài liệu công ty',
        path: '/documentCompanyView'
      },
      {
        label: 'Quản lý tài liệu',
        path: '/documentCompany'
      }
    ]
  },
  {
    label: 'Hệ Thống',
    Icon: SettingsOutlinedIcon,
    child: [
      {
        label: 'Phân quyền',
        path: '/role'
      },
      {
        label: 'Lịch sử thay đổi dữ liệu',
        path: '/audit'
      },
      {
        label: 'Quản lý danh mục',
        path: '/attribute'
      },
      { label: 'Mẫu báo giá bảo trì', path: '/maintainanceQuote' },
      {
        label: 'Cài đặt',
        path: '/setting'
      },
      {
        label: 'Quản lý banner',
        path: '/banner'
      }
    ]
  }
];

export default React.memo(Nav);
