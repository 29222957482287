import React, { useEffect, Fragment, createContext, useState } from 'react';
import Dialog from './Dialog';
//-------------------------------------

export const DialogContext = createContext();

const DialogProvider = React.memo(({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [dialogBody, setDialogBody] = useState();
  const [decision, setDecision] = useState({});

  useEffect(() => {
    if (!isOpen) {
      setDialogBody();
      setDialogProps({});
    }
  }, [isOpen]);

  const confirm = async ({ dialogProps, onConfirm, onCancel }) => {
    onConfirm = onConfirm || (() => null);
    onCancel = onCancel || (() => null);
    setDialogProps(dialogProps);
    setIsOpen(true);
    const decision = new Promise(resolve => setDecision({ resolve }));
    const res = await decision;
    setIsOpen(false);
    if (res) onConfirm();
    else onCancel();
  };

  const show = ({ dialogProps, body }) => {
    setDialogProps(dialogProps);
    setDialogBody(body);
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const dialog = { confirm, show, close };

  return (
    <Fragment>
      <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
      <Dialog
        isOpen={isOpen}
        decision={decision}
        onCancel={() => null}
        onClose={() => setIsOpen(false)}
        dialogProps={dialogProps}
        body={dialogBody}
      />
    </Fragment>
  );
});

export default DialogProvider;
