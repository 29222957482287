import React from 'react';
import { Button, Switch } from '@blueprintjs/core';
import './FieldMenu.css';

const FieldMenu = ({ fields, label, checker }) => {
  const { key, checks, check, reset } = checker;
  return (
    <div>
      <div style={{ width: 400 }} className="field-filter">
        {fields?.map((field, i) => (
          <div key={i} className="field-checkbox">
            <Switch
              onChange={() => check(field[key])}
              checked={checks[field[key]]}
            />
            <span className="field-text" onClick={() => check(field[key])}>
              {field[label]}
            </span>
          </div>
        ))}
      </div>
      <div className="filter-footer">
        <Button
          icon="reset"
          text="Đặt lại"
          minimal
          intent="primary"
          onClick={() => reset()}
        />
      </div>
    </div>
  );
};

export default FieldMenu;
