import React, { useEffect, useRef, useState } from 'react';
import './ScrollButton.css';
import VisibilitySensor from 'react-visibility-sensor';

function ScrollButton({ component }) {
  const ref = useRef();
  const visible = useRef();
  const [width, setWidth] = useState();

  useEffect(() => {
    if (ref != null) {
      window.addEventListener('resize', resizeWidth);
      return () => {
        window.removeEventListener('resize', resizeWidth);
      };
    }
  });
  useEffect(() => {
    if (ref !== null) {
      document.addEventListener('scroll', Scrolling);
      return () => {
        document.removeEventListener('scroll', Scrolling);
      };
    }
  });
  useEffect(() => {
    ref.current.style.padding = '10px ' + width + 'px';
  }, [width]);
  function resizeWidth() {
    setWidth((document.body.clientWidth - visible.current.clientWidth) / 2);
  }
  function Scrolling() {
    setWidth((document.body.clientWidth - visible.current.clientWidth) / 2);
  }

  function onChange(isVisible) {
    if (ref != null) {
      setWidth((document.body.clientWidth - visible.current.clientWidth) / 2);
    }
    if (isVisible === true) {
      ref.current.style.display = 'none';
    } else {
      ref.current.style.display = 'block';
    }
  }
  return (
    <React.Fragment>
      <div ref={ref} id="invisible-btn">
        {component()}
      </div>
      <VisibilitySensor onChange={onChange}>
        <div ref={visible}>{component()}</div>
      </VisibilitySensor>
    </React.Fragment>
  );
}

export default ScrollButton;
