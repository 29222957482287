import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// import { SvgIconProps } from '@material-ui/core/SvgIcon'

import List from '@material-ui/core/List';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import AppMenuItemComponent from './AppMenuItemComponent';
import Fade from '@material-ui/core/Fade';
import useResizeObserver from '../../common/hooks/useResizeObserver';

export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array
};

const AppMenuItem = props => {
  const { label, path, Icon, child = [], expand, setExpand, index } = props;
  const level = props.level || 0;
  const indent = (() => {
    switch (level) {
      case 0:
        return 0;
      case 1:
        return 36;
      case 2:
        return 52;
      default:
        return 36;
    }
  })();

  const useStyles = makeStyles(theme =>
    createStyles({
      menuItem: {
        color: '#CCCCCC',
        marginLeft: 6,
        marginBottom: 5,
        borderRadius: 8,
        padding: '6px 12px 6px 12px',
        '&.active': {
          color: '#fff',
          background: 'rgba(255, 255, 255, 0.1)',
          '& .MuiListItemIcon-root': {
            color: '#F89E49'
          }
        },
        '&:hover': {
          background: 'rgba(255, 255, 255, 0.1)',
          color: '#fff',
          '& .MuiListItemIcon-root': {
            color: '#F89E49'
          }
        },
        '& .MuiListItemIcon-root': {
          minWidth: 35
        }
      },
      menuItemIcon: {
        marginTop: 4,
        marginBottom: 4,
        color: '#B1B1B1'
      },
      label: {
        fontWeight: 600,
        paddingLeft: indent
      },
      divider: {
        height: 3,
        backgroundColor: '#F89E49'
      }
    })
  );

  const classes = useStyles();
  const isExpandable = child && child.length > 0;
  const [childExpand, setChildExpand] = useState(() =>
    child.map(item => false)
  );

  function handleClick() {
    setExpand(expands =>
      expands.map((item, i) => (i === index ? !item : false))
    );
  }

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem + ' nav-label-wrap'}
      link={path}
      onClick={handleClick}
    >
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        primary={label}
        inset={false}
        className="nav-label-text"
        classes={{ primary: classes.label }}
      />
      {isExpandable && !expand && <IconExpandMore className="menu-expand" />}
      {isExpandable && expand && <IconExpandLess className="menu-expand" />}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={expand} timeout="auto" unmountOnExit>
      <Divider className="menu-divider" classes={{ root: classes.divider }} />
      <div className="menu-background">
        <List className="menu-list" component="div" disablePadding>
          {child.map((item, index) => (
            <AppMenuItem
              {...item}
              level={level + 1}
              key={index}
              index={index}
              expand={childExpand[index]}
              setExpand={setChildExpand}
            />
          ))}
        </List>
      </div>
      <Divider className="menu-divider" classes={{ root: classes.divider }} />
    </Collapse>
  ) : null;

  return (
    <div className="menu-wrapper">
      {MenuItemRoot}
      {MenuItemChildren}
    </div>
  );
};

export default AppMenuItem;
