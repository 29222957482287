import React, { useMemo } from 'react';
import { Breadcrumbs, Breadcrumb } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';

function MyBreadcrumbs({ crumbs }) {
  const history = useHistory();

  function handleChangeRoute(path) {
    history.push(path);
  }
  const _crumbs = useMemo(
    () => crumbs.slice(0, crumbs.length - 1).concat({ name: '' }),
    [crumbs]
  );

  function breadcrumbRenderer({ path, name, ...restProps }) {
    return (
      <Breadcrumb {...restProps} onClick={() => handleChangeRoute(path)}>
        {name}
      </Breadcrumb>
    );
  }

  return (
    <Breadcrumbs items={_crumbs} breadcrumbRenderer={breadcrumbRenderer} />
  );
}

export default MyBreadcrumbs;
