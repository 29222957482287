import React from 'react';
import { history, Link } from 'react-router-dom';
import './Notification.css';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TimerOffOutlinedIcon from '@material-ui/icons/TimerOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { useFetch } from '../../common/hooks/api';
import { useAuthContext } from '../../common/AuthProvider';
import { useDialog } from '../../shared/Dialog';
import danger from './danger.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  fontSizeLarge: {
    fontSize: '3rem'
  },
  primary: {
    fontSize: 'unset'
  }
}));

export default ({ onClose }) => {
  const classes = useStyles();
  const { noties } = useAuthContext();
  // const notiAPI = useFetch(['get', 'notification']);

  return (
    <div className="noti-list-container">
      <List className={classes.root}>
        {noties?.data?.map(noti => (
          <NotiContainer noti={noti} onClose={onClose} />
        ))}
      </List>
    </div>
  );
};

function NotiContainer({ noti, onClose }) {
  const classes = useStyles();
  const { data } = noti;

  return (
    <>
      <Link
        // to={`/task/${noti.id}`}
        style={{ textDecoration: 'none', color: 'unset' }}
        onClick={onClose}
      >
        <ListItem alignItems="flex-start" classes={{ root: 'noti-container' }}>
          <ListItemAvatar>{IconType(noti.type)}</ListItemAvatar>
          <ListItemText
            className={classes.primary}
            classes={{ secondary: 'color-text-secondary' }}
            primary={noti.data?.message}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                  classes={{ colorTextPrimary: 'color-text-primary' }}
                >
                  {noti.data?.primaryText}
                </Typography>
                {' - ' + noti.data?.secondaryText}
              </React.Fragment>
            }
          />
        </ListItem>
      </Link>
      <Divider variant="inset" component="li" />
    </>
  );
  return null;
}
const IconType = value => {
  const classes = useStyles();
  return (
    <span>
      {value === 'App\\Notifications\\TaskOverdue' && (
        <TimerOffOutlinedIcon
          fontSize="large"
          className={classes.fontSizeLarge}
        />
      )}
      {value === 'App\\Notifications\\NewAccident' && (
        <ReportProblemOutlinedIcon
          fontSize="large"
          className={classes.fontSizeLarge}
        />
      )}
      {value === 'App\\Notifications\\TaskComplete' && (
        <CheckCircleOutlineOutlinedIcon
          fontSize="large"
          className={classes.fontSizeLarge}
        />
      )}
      {value === 'App\\Notifications\\ResolvedAccident' && (
        <CheckCircleOutlineOutlinedIcon
          fontSize="large"
          className={classes.fontSizeLarge}
        />
      )}
      {value === 'App\\Notifications\\ResolvedPayment' && (
        <CheckCircleOutlineOutlinedIcon
          fontSize="large"
          className={classes.fontSizeLarge}
        />
      )}
      {value === 'App\\Notifications\\PaymentExpired' && (
        <TimerOffOutlinedIcon
          fontSize="large"
          className={classes.fontSizeLarge}
        />
      )}
      {value === 'App\\Notifications\\PaymentExpiring' && (
        <AccessAlarmOutlinedIcon
          fontSize="large"
          className={classes.fontSizeLarge}
        />
      )}
      {value === 'App\\Notifications\\ContractExpired' && (
        <TimerOffOutlinedIcon
          fontSize="large"
          className={classes.fontSizeLarge}
        />
      )}
      {value === 'App\\Notifications\\ContractExpiring' && (
        <AccessAlarmOutlinedIcon
          fontSize="large"
          className={classes.fontSizeLarge}
        />
      )}
    </span>
  );
};
