import { Position, Toaster } from '@blueprintjs/core';

const CustomToaster = Toaster.create({
  className: 'toaster',
  position: Position.TOP,
  maxToasts: 3
});

export const savedToast = () =>
  CustomToaster.show({
    message: 'Lưu thành công',
    icon: 'saved',
    intent: 'success'
  });
export const deletedToast = () =>
  CustomToaster.show({
    message: 'Xóa thành công',
    icon: 'trash',
    intent: 'success'
  });
export const restoredToast = () =>
  CustomToaster.show({
    message: 'Khôi phục thành công',
    icon: 'refresh',
    intent: 'success'
  });

export default CustomToaster;
