import { format } from 'timeago.js';
import Moment from 'moment';
import { find } from 'lodash';

export const numberWithCommas = x =>
  x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatDate = t => {
  let currentDate = new Date();
  let date = new Date(t);
  if (t == null) {
    return t;
  }
  if ((currentDate - date) / 86400000 <= 1) {
    return format(t, 'vi');
  } else {
    return Moment(date).format('DD/MM/YYYY hh:mm:ss');
  }
};

export const mapResData = (data, forms) => {
  const fields = forms
    .reduce((a, c) => [...a, ...c], [])
    .map(form => form.field);
  let result = {};
  Object.keys(data)
    .filter(key => fields.includes(key))
    .forEach(key => {
      result = Object.assign(result, { [key]: data[key] });
    });
  return result;
};
export const mapAttributes = data => {
  let schemes = [],
    vals = [];
  data.forEach(at => {
    if (find(schemes, f => f.id === at.group_id) === null) {
      schemes.push({
        group_id: at.group.id,
        group_name: at.group.name,
        attributes: at.group.children
      });
    }
    //
    vals.push({
      id: at.id,
      type: at.value_type,
      value: at.pivot.value,
      value_list: at.value_list
    });
  });
  return { schemes, vals };
};
export const formatNumber = (x, y) =>
  Math.round((x / y) * 100 * 100 + Number.EPSILON) / 100;

//-------------------------UTILS
export function mapAttribute(data) {
  return {
    ...data,
    attributes:
      data.attributes?.reduce(
        (map, attr) => ({ ...map, [attr.id]: attr.pivot.value }),
        {}
      ) || {}
  };
}
// formdata
export const toFormData = data => {
  try {
    let formData = new FormData();
    for (const [key, val] of Object.entries(data)) {
      if (val === null || JSON.stringify(val) === '{}') continue;
      if (key === 'newFiles') {
        val.forEach(file => formData.append(`${key}[]`, file));
        continue;
      }
      if (typeof val === 'object') {
        formData.append(key, JSON.stringify(val));
        continue;
      }
      formData.append(key, val);
    }
    return formData;
  } catch (e) {}
};
