import React, { Fragment, useEffect, useRef, useState } from 'react';
import Moment from 'moment';
import { Switch } from '@blueprintjs/core';
import { TextField } from '@material-ui/core';
import { DateRangeInput } from '@blueprintjs/datetime';

import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';

import { Row, Col } from 'react-bootstrap';
import { inputTypes } from '../../common/constants';
import QuerySelect from './QuerySelect';
import MultiSelect from './MultiSelect';
import SelectConstruction from './SelectConstruction';
import SelectPerson from './SelectPerson';
import SelectMultiPerson from './SelectMultiPerson';
import { TextFieldWrap } from './TextField';
import Select from './Select';
import { NumberFieldWrap } from './Number';
import Checkbox from './Checkbox';
import TagInput from './TagInput';
import DatePicker from './DatePicker';
import Radio from './Radio';
import QueryCheckbox from './QueryCheckbox';

import RegionSelect from './RegionSelect';
import Link from './Link';
import DialogMui from '../DialogMui';
import UserForm from '../../views/User/UserForm';
//-------------------------------------

const Forms = React.memo(
  ({ inputs, loading, readOnly, variant, data, onChange, errors }) => {
    //scroll into first error
    const ref = useRef();
    useEffect(() => {
      if (ref.current) {
        ref.current.scrollIntoView();
      }
    });

    return (
      <Fragment>
        {inputs?.map((row, index) => (
          <Row key={index}>
            {row.map((column, index) => {
              if (!column) return null;
              const { type, label, grid, field, ...otherProps } = column;
              if (type === 'link') {
                return (
                  <Col
                    xs={12}
                    md={4}
                    {...grid}
                    key={index}
                    style={{ marginBottom: 20 }}
                  >
                    <Link
                      label={label}
                      link={`/${field}/${data[field].id}`}
                      name={data[field][otherProps.labelField || 'name']}
                    />
                  </Col>
                );
              }
              if (type === 'region') {
                if (readOnly) {
                  if (!otherProps.readOnlyValue) return null;
                  return (
                    <Col xs={12} key={index} style={{ marginBottom: 15 }}>
                      <div className="readonly-wrapper">
                        <label className="readonly-label">{label}</label>
                        <span className="readonly-value text-break">
                          {otherProps.readOnlyValue}
                        </span>
                      </div>
                    </Col>
                  );
                }

                return (
                  <Col xs={12} key={index}>
                    <RegionSelect
                      variant={variant || 'outlined'}
                      label={label}
                      values={data}
                      onChange={otherProps.onChange}
                      loading={loading}
                      readOnly={readOnly}
                      error={errors[field]}
                      {...otherProps}
                    />
                  </Col>
                );
              }

              return (
                <Col xs={12} md={4} {...grid} key={index}>
                  <div
                    id={field}
                    ref={errors[field] && !ref.current ? ref : null}
                  >
                    {column.component ? (
                      <column.component />
                    ) : (
                      <Input
                        variant={variant || 'outlined'}
                        type={type}
                        label={label}
                        value={data[field]}
                        onChange={val => onChange(field, val)}
                        error={errors[field]}
                        loading={loading}
                        readOnly={readOnly}
                        {...otherProps}
                      />
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
        ))}
      </Fragment>
    );
  }
);

export const FlatForms = React.memo(({ inputs, formik, variant, ...other }) => {
  return (
    <Row {...other.rowProps}>
      {inputs.map((input, index) => {
        const {
          type,
          label,
          field,
          value,
          onChange,
          error,
          grid,
          ...otherProps
        } = input;
        return (
          <Col xs={12} {...input.grid} key={index}>
            <Input
              // controlled={['number', 'text'].includes(type) ? true : ''}
              variant={variant || 'standard'}
              type={type === 'select' ? 'checkbox' : type}
              label={label}
              value={formik.values[field]}
              onChange={value =>
                formik.setFieldValue(
                  field,
                  type === 'query-select' ? value?.[input.valueField] : value
                )
              }
              {...otherProps}
            />
          </Col>
        );
      })}
    </Row>
  );
});

export const Input = React.memo(
  ({
    type,
    label,
    value,
    onChange,
    error,
    loading,
    readOnly,
    link,
    ...otherProps
  }) => {
    if (readOnly && !value) return null;
    const [show, setShow] = useState(false);
    function renderReadOnly() {
      switch (type) {
        case inputTypes.TEXT:
          return <span className="readonly-value text-break">{value}</span>;

        case inputTypes.PASSWORD:
          return (
            <TextField
              className="standard-required"
              label={label}
              value={value || ''}
              fullWidth={true}
              shrink="true"
              onChange={onChange}
            />
          );

        case inputTypes.NUMBER:
          return <span className="readonly-value text-break">{value}</span>;
        //-----------------no read-only
        case inputTypes.RADIO:
          return (
            <span className="readonly-value text-break">
              {otherProps.options.find(option => option.value == value)?.label}
            </span>
          );
        case 'select':
          return otherProps?.options
            ?.filter(x => x.value === value)
            .map(a => (
              <span className="readonly-value text-break">{a?.label}</span>
            ));

        case 'select-user':
          return (
            <Fragment>
              <DialogMui
                title={
                  otherProps.readOnlyValue?.id
                    ? 'Thông tin nhân viên'
                    : 'Thêm nhân viên'
                }
                open={show}
                onClose={() => setShow(false)}
                content={
                  <UserForm
                    data={otherProps.readOnlyValue}
                    onClose={() => {
                      setShow(false);
                    }}
                  />
                }
              />
              <span className="readonly-value text-break">
                <a
                  style={{ color: '#1155cc' }}
                  rel="noopener noreferrer"
                  onClick={() => setShow(true)}
                >
                  {otherProps.readOnlyValue?.name}
                </a>
              </span>
            </Fragment>
          );
        case 'select-customer':
          return (
            <span className="readonly-value text-break">
              <a
                href={`/customer/${otherProps.readOnlyValue?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {otherProps.readOnlyValue?.name}
              </a>
            </span>
          );
        case 'select-construction':
          return (
            <span className="readonly-value text-break">
              <a
                href={`/construction/${otherProps.readOnlyValue?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {otherProps.readOnlyValue?.name}
              </a>
            </span>
          );
        case 'query-select':
          return (
            <span className="readonly-value text-break">
              {otherProps.readOnlyValue?.name}
            </span>
          );
        case 'date':
          return (
            <span className="readonly-value text-break">
              {value ? Moment(value).format('DD/MM/YYYY') : null}
            </span>
          );
        default:
          return null;
      }
    }

    function render() {
      switch (type) {
        case inputTypes.TEXT:
        case inputTypes.PASSWORD:
        case inputTypes.TEXTAREA:
          return (
            <TextFieldWrap
              {...otherProps}
              type={type}
              label={label}
              value={value}
              onChange={onChange}
              error={error}
            />
          );
        case inputTypes.NUMBER:
          return (
            <NumberFieldWrap
              {...otherProps}
              type={type}
              label={label}
              value={value}
              onChange={onChange}
              error={error}
            />
          );
        case inputTypes.SELECT:
          return (
            <Select
              {...otherProps}
              label={label}
              value={value}
              onChange={onChange}
              error={error}
            />
          );
        case inputTypes.CHECKBOX:
          return (
            <Checkbox
              {...otherProps}
              label={label}
              value={value}
              onChange={onChange}
              error={error}
            />
          );
        case 'query-select':
          return (
            <QuerySelect
              endpoint={otherProps.endpoint}
              labelField={otherProps.labelField}
              valueField={otherProps.valueField}
              queryField={otherProps.queryField}
              value={value}
              onChange={onChange}
              label={label}
              error={error}
              {...otherProps}
            />
          );
        case 'multiselect':
          return (
            <MultiSelect
              endpoint={otherProps.endpoint}
              labelField={otherProps.labelField}
              valueField={otherProps.valueField}
              queryField={otherProps.queryField}
              value={value}
              onChange={onChange}
              label={label}
              error={error}
              {...otherProps}
            />
          );
        case 'cate-checkbox':
        case 'query-checkbox':
          return (
            <QueryCheckbox
              label={label}
              value={value}
              onChange={onChange}
              {...otherProps}
            />
          );
        case inputTypes.RADIO:
          return (
            <Radio
              {...otherProps}
              label={label}
              onChange={onChange}
              value={value}
              error={error}
            />
          );
        case 'switch':
          return (
            <React.Fragment>
              {otherProps.options.map((option, index) => (
                <Switch
                  key={index}
                  label={option}
                  checked={value?.includes(option)}
                  onChange={e =>
                    onChange(
                      e.target.checked
                        ? [...value, option]
                        : value.filter(item => item !== option)
                    )
                  }
                  disabled={readOnly}
                />
              ))}
            </React.Fragment>
          );

        //-----------------no read-only
        case 'select-user':
        case 'select-customer':
          return (
            <SelectPerson
              type={type}
              label={label}
              onChange={onChange}
              error={error}
              value={value}
              readOnly={readOnly}
              {...otherProps}
            />
          );
        case 'select-construction':
          return (
            <SelectConstruction
              onChange={onChange}
              value={value}
              error={error}
              readOnly={readOnly}
              {...otherProps}
            />
          );
        case 'select-multi-person':
          return (
            <SelectMultiPerson
              label={label}
              onChange={onChange}
              error={error}
              value={value}
              readOnly={readOnly}
              {...otherProps}
            />
          );
        case 'date':
          return (
            <DatePicker
              label={label}
              value={value}
              onChange={onChange}
              error={error}
              {...otherProps}
            />
          );
        case 'tag':
          return (
            <TagInput
              defaultValue={value?.split(',') || []}
              label={label}
              fullWidth
              onChange={values => onChange(values.join(','))}
            />
          );
        case 'date-range-bp':
          return (
            <DateRangeInput
              shortcuts={false}
              locale="vi"
              localeUtils={MomentLocaleUtils}
              className="dateRange"
              formatDate={date => Moment(date).format('DD/MM/YYYY')}
              parseDate={str =>
                new Date(Moment(str, 'DD/MM/YYYY').format('YYYY/MM/DD'))
              }
              value={[
                value && value[0] ? new Date(value[0]) : null,
                value && value[1] ? new Date(value[1]) : null
              ]}
              onChange={date => {
                onChange([
                  Moment(date[0]).format('YYYY-MM-DD'),
                  Moment(date[1]).format('YYYY-MM-DD')
                ]);
              }}
              contiguousCalendarMonths={false}
              timePrecision={false}
              closeOnSelection={false}
              placeholder="DD/MM/YY"
              startInputProps={{ placeholder: 'Từ...' }}
              endInputProps={{ placeholder: 'Đến...' }}
              popoverProps={{
                captureDismiss: true,
                usePortal: false
              }}
              {...otherProps}
            />
          );

        default:
          return null;
      }
    }

    if (type === 'region') {
      return (
        <RegionSelect values={value} onChange={onChange} {...otherProps} />
      );
    }

    return (
      <div style={{ marginBottom: 20 }}>
        <div className={loading ? 'bp3-skeleton' : null}>
          {readOnly ? (
            <div className="readonly-wrapper">
              <label className="readonly-label">{label}</label>
              {renderReadOnly()}
            </div>
          ) : (
            render()
          )}
        </div>
      </div>
    );
  }
);

export default Forms;
