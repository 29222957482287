import React, { forwardRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useDialog } from '../../shared/Dialog';
import ChangeInfo from '../ChangeInfo/ChangeInfo';
import ChangePassword from '../ChangeInfo/ChangePassword';

const AppMenuItemComponent = props => {
  const dialog = useDialog();
  const { className, onClick, link, children } = props;
  const changeInfo = () => {
    dialog.show({
      dialogProps: {
        icon: 'user',
        title: 'Thay đổi thông tin người dùng',
        style: { margin: '20px 20px 20px 60px', maxWidth: '800px' }
      },
      body: (
        <ChangeInfo
          onClose={() => {
            dialog.close();
          }}
        />
      )
    });
  };
  const handleChangePassword = () => {
    dialog.show({
      dialogProps: {
        icon: 'key',
        title: 'Đổi mật khẩu',
        style: { margin: '20px 20px 20px 60px', maxWidth: '800px' }
      },
      body: (
        <ChangePassword
          onClose={() => {
            dialog.close();
          }}
        />
      )
    });
  };

  if (typeof link === 'function') {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={link}
      />
    );
  }
  // If link is not set return the orinary ListItem
  if (!link || typeof link !== 'string') {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={onClick}
      />
    );
  }
  if (!link || link === '/changeInfo') {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={changeInfo}
      />
    );
  }
  if (!link || link === '/changePassWord') {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={handleChangePassword}
      />
    );
  }

  // Return a LitItem with a link component
  return (
    <ListItem
      button
      onClick={onClick}
      className={className}
      children={children}
      component={forwardRef((props, ref) => (
        <NavLink exact {...props} innerRef={ref} />
      ))}
      to={link}
    />
  );
};

export default AppMenuItemComponent;
