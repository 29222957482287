import { useState, useMemo, useCallback, useEffect } from 'react';
import useSWR from 'swr';

import local_storage from '../localStorage';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Toaster from '../../shared/Toaster';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const api = axios.create({ timeout: 30000 });

function useFetch(key, options) {
  const { fetcher, loading: fetching } = useAPI();
  const { data, error, revalidate } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
    ...options
  });

  useEffect(() => {
    if (options?.initialData) {
      if (data === options.initialData) revalidate();
    }
  }, []);

  useEffect(() => {
    if (options?.onData && data) {
      options.onData(data);
    }
  }, [data]);

  const loading = !data || data === options?.initialData;

  return { data, error, revalidate, loading };
}

function useAPI(initData) {
  const history = useHistory();
  const [error, setError] = useState({});
  const [data, setData] = useState(initData);
  const [loading, setLoading] = useState(false);
  const [flags, setFlags] = useState([]); //hold flags for canceling request

  const fetcher = useMemo(
    () => async (method, endpoint, params, config = {}) => {
      //ignore flag
      let ignore = false;
      setFlags(flags => [...flags, () => (ignore = true)]);
      //
      setError({});
      setLoading(true);
      api.defaults.headers.common['Authorization'] =
        'Bearer ' + local_storage.getAccessToken();
      const res = await api[method](
        endpoint,
        typeof params === 'string' ? JSON.parse(params) : params,
        config
      )
        .catch(e => Promise.reject(errorResponse(e, history)))
        // .finally(() => (ignore ? null : setLoading(false)));
        .finally(() => setLoading(false));
      if (ignore) console.log(`[API] ${endpoint} cancelled`);
      return ignore ? res?.data : successResponse(res);
    },

    []
  );

  useEffect(
    () => () => {
      if (flags.length > 0) {
        flags.forEach(flag => flag());
        setFlags([]);
      }
    },
    [flags]
  );

  function errorResponse(error) {
    if (error.response) {
      const {
        response: { data, status }
      } = error;

      if (status === 401) {
        history.push('/error-401');
      }
      if (status === 422) {
        setError(data.errors);
      }
      return { data, status };
    } else {
      Toaster.show({
        message: 'Lỗi máy chủ',
        intent: 'danger',
        icon: 'error'
      });
      return null;
    }
  }

  function successResponse(res) {
    let { data } = res;
    setData(data);
    return data;
  }

  return { fetcher, data, setData, error, setError, loading };
}

export { useAPI, useFetch };
