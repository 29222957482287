import React, { useEffect, useState } from 'react';
import { Carousel } from 'rsuite';
import { useAPI, useFetch } from '../../common/hooks/api';
export default function CarouselWrapper({ params }) {
  const [files, setFiles] = useState([]);
  const api = useAPI();
  const data = useFetch(['get', 'banner', JSON.stringify({ params })]);
  useEffect(() => {
    if (data?.data?.data[0]?.files.length > 0) {
      setFiles(data?.data?.data[0]?.files);
    }
  }, [data?.data]);
  console.log({ files });
  return (
    <Carousel
      autoplay
      autoplayInterval={5000}
      className="custom-slider"
      shape="bar"
    >
      {files.map(file => (
        <img className="slide" src={file.image_url} height="250" />
      ))}

      {/*<img className="slide" src="/slide2.jpg" height="250" />*/}
      {/*<img className="slide" src="/slide3.jpg" height="250" />*/}
      {/*<img className="slide" src="/slide4.jpg" height="250" />*/}
      {/*<img className="slide" src="/slide5.jpg" height="250" />*/}
    </Carousel>
  );
}
