import React, { useCallback } from 'react';
import QuerySelect from './QuerySelect';

const smallText = { marginTop: 0, color: '#636363', fontSize: 15 };

function SelectConstruction(props) {
  const renderOption = useCallback(option => {
    const smallText = { marginTop: 0, color: '#636363', fontSize: 14 };
    return (
      <div style={{ padding: 5 }}>
        <p>{option.name}</p>
        <span style={smallText}>
          <p>{`Khách hàng: ${option?.customer?.name}`}</p>
        </span>
        <span style={smallText}>
          <p>{`Loại công trình: ${option?.construction_type?.name}`}</p>
        </span>
      </div>
    );
  }, []);

  return (
    <QuerySelect
      {...props}
      label="Công trình"
      endpoint={props.endpoint}
      queryField="name"
      labelField="name"
      renderOption={renderOption}
    />
  );
}

export default SelectConstruction;
