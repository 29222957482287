import React, { Fragment, useState } from 'react';
import {
  InputGroup,
  ControlGroup,
  Button,
  ButtonGroup
} from '@blueprintjs/core';
import './Pagination.css';

function Pagination({
  setParams,
  params,
  lastPage,
  currentPage,
  total,
  perPageMenu,
  per_page
}) {
  const [toPage, setToPage] = useState(1);
  const perPage = perPageMenu || [20, 50, 100];
  let totalPage = [];
  let allPage = [];
  let delta = 2;
  let l;

  function handleChangePage(page) {
    const newParams = { ...params };
    newParams.page = page;
    setParams(newParams);
  }

  function changePage(event) {
    event.preventDefault();
    if (event.target.value > lastPage) {
      event.target.value = lastPage;
    }
    if (event.target.value < 0) {
      event.target.value = 1;
    }
    setToPage(event.target.value);
  }

  for (let i = 1; i <= lastPage; i++) {
    if ((currentPage == 1 || currentPage == 2) && lastPage >= 5) {
      allPage.push(i);
      if (i == 5) {
        break;
      }
    } else if (
      (currentPage == lastPage || currentPage == lastPage - 1) &&
      lastPage >= 5 &&
      i > lastPage - 5
    ) {
      allPage.push(i);
    } else {
      if (
        // i === 1 ||
        // i === lastPage ||
        i >= currentPage - delta &&
        i < currentPage + delta + 1
      ) {
        allPage.push(i);
      }
    }
  }

  for (let i of allPage) {
    l = i;
    if (l) {
      if (i - l === delta) {
        totalPage.push(l + 1);
      }
      // else if (i - l !== 1) {
      //   totalPage.push('...');
      // }
    }
    totalPage.push(i);
    // l = i;
  }

  function handlePerPage(event) {
    const newParams = { ...params };
    newParams.perPage = event.target.value;
    newParams.page = 1;
    setParams(newParams);
    setToPage(1);
  }

  const listPage = totalPage.map((page, index) => (
    <Button
      key={index}
      // disabled={page === '...' ? true : false}
      title={'Trang ' + page}
      onClick={() => handleChangePage(page)}
      active={page === currentPage}
      tabIndex="0"
      // minimal
    >
      {page}
    </Button>
  ));

  const rowPerPage = perPage.map((item, index) => (
    <option value={item} key={index}>
      {item}
    </option>
  ));

  const pagi = (
    <div className="wrapper">
      <div className="left" style={{ marginRight: 10 }}>
        <div
          className="bp3-select bp3-minimal .modifier"
          style={{ marginLeft: '10px' }}
        >
          <select onChange={handlePerPage} value={per_page}>
            {rowPerPage}
          </select>
        </div>
        {/*<ControlGroup fill={true} vertical={false}>*/}
        {/*  <Button onClick={() => handleChangePage(toPage)}>Tới trang</Button>*/}
        {/*  <InputGroup*/}
        {/*    id="text-input"*/}
        {/*    type={'number'}*/}
        {/*    onChange={changePage}*/}
        {/*    min={1}*/}
        {/*    max={lastPage}*/}
        {/*    value={toPage}*/}
        {/*  />*/}
        {/*</ControlGroup>*/}
        <span>Tổng: {total}</span>
      </div>
      <ButtonGroup>
        {(currentPage > 1 && lastPage > 1) ||
        (currentPage == 1 && lastPage == 1) ? (
          <Fragment>
            <Button
              disabled={currentPage === 1}
              tabIndex="0"
              onClick={() => handleChangePage(1)}
              // minimal
              text="Trang đầu"
            />
            <Button
              disabled={currentPage === 1}
              className="bp3-button bp3-icon-chevron-left"
              tabIndex="0"
              onClick={() => handleChangePage(currentPage - 1)}
              // minimal
            />
          </Fragment>
        ) : (
          ''
        )}
        {listPage}
        {(currentPage < lastPage && lastPage > 1) ||
        (currentPage == 1 && lastPage == 1) ? (
          <Fragment>
            <Button
              disabled={currentPage === lastPage}
              className="bp3-button bp3-icon-chevron-right"
              tabIndex="0"
              onClick={() => handleChangePage(currentPage + 1)}
              // minimal
            />
            <Button
              disabled={currentPage === lastPage}
              tabIndex="0"
              onClick={() => handleChangePage(lastPage)}
              // minimal
              text="Trang cuối"
            />
          </Fragment>
        ) : (
          ''
        )}
      </ButtonGroup>
    </div>
  );
  return <React.Fragment>{pagi}</React.Fragment>;
}

export default Pagination;
