import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Card,
  Button,
  Alert,
  FormGroup,
  Intent,
  InputGroup,
  Alignment,
  Divider
} from '@blueprintjs/core';
import { useAPI, useFetch } from '../../common/hooks/api';
import { useHistory, useLocation } from 'react-router-dom';
import storage from '../../common/localStorage';
import * as yup from 'yup';
import CarouselWrapper from './Carousel';

function ResetPassword() {
  const location = useLocation();
  const history = useHistory();
  const [dataOfUser, setData] = useState();
  const [params, setParams] = useState();
  const [open, setOpen] = useState(false);
  const { fetcher, loading } = useAPI();
  useEffect(() => {
    fetcher('get', `password/find/${location.search.slice(7)}`).then(data => {
      setData(data);
    });
  }, []);
  const token = params?.token;
  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: ''
    },
    validationSchema: yup.object().shape({
      password: yup.string().required('không để trống'),
      password_confirmation: yup
        .string()
        .required('không để trống')
        .oneOf([yup.ref('password'), null], 'Mật khẩu không trùng khớp')
    }),
    onSubmit: async values => {
      const res = await fetcher('post', 'password/reset', {
        password: values.password,
        password_confirmation: values.password_confirmation,
        email: dataOfUser.email,
        token: dataOfUser.token
      }).then(() => setOpen(true));
      if (res && res.errors) {
        formik.setErrors(res.errors);
      }
      if (res && res.error) {
        // showToast(res.error);
      }
    }
  });

  function handleClose() {
    setOpen(false);
  }

  function login() {
    window.location.href = history.push('/');
  }

  //React-router

  return (
    // <Card>
    //     <h5>Điền thông tin để thay đổi mật khẩu</h5>
    //     <Forms forms={forms} formik={formik} error={error} />
    //     <Button text="Xác nhận"  onClick={formik.handleSubmit} />
    //
    // </Card>
    <div>
      <div className="body">
        <CarouselWrapper />
        <form className="form" onSubmit={formik.handleSubmit}>
          <Card className="card">
            <img className="logo" src="/atvin_logo.png" />
            <Alert
              confirmButtonText="Ok"
              isOpen={open}
              onClose={handleClose}
              onConfirm={login}
            >
              <p>Đổi mật khẩu thành công!!!</p>
            </Alert>
            <FormGroup
              label="Mật khẩu"
              helperText={formik.errors.password && formik.errors.password}
              intent={formik.errors.password && Intent.DANGER}
            >
              <InputGroup
                type="password"
                large={true}
                value={formik.values.password}
                onChange={formik.handleChange('password')}
                onBlur={formik.handleBlur('password')}
                intent={formik.errors.password && Intent.DANGER}
              />
            </FormGroup>
            <FormGroup
              label="Nhập lại mật khẩu"
              helperText={
                formik.errors.password_confirmation &&
                formik.errors.password_confirmation
              }
              intent={formik.errors.password_confirmation && Intent.DANGER}
            >
              <InputGroup
                type="password"
                large={true}
                value={formik.values.password_confirmation}
                onChange={formik.handleChange('password_confirmation')}
                onBlur={formik.handleBlur('password_confirmation')}
                intent={formik.errors.password_confirmation && Intent.DANGER}
              />
            </FormGroup>
            <Button
              text="Xác nhân"
              alignText={Alignment.CENTER}
              large={true}
              fill={true}
              intent={Intent.PRIMARY}
              loading={loading}
              type="submit"
            />
            <Divider className="divider" />
          </Card>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
