import React, { useState, Fragment, useEffect } from 'react';
import { Popover } from '@material-ui/core';
import './FieldMenu.css';

export default React.memo(
  ({ children, content, setCloseHandler, ...other }) => {
    const [anchor, setAnchor] = useState(false);
    let childClone = children && {
      ...children,
      props: {
        onClick: event => {
          setAnchor(event.currentTarget);
        },
        ...children.props
      }
    };
    useEffect(() => {
      if (setCloseHandler) {
        setCloseHandler(() => () => setAnchor());
      }
    }, []);

    return (
      <Fragment>
        <Popover
          transitionDuration={100}
          keepMounted
          className="simple-popover"
          open={!!anchor}
          anchorEl={anchor || {}}
          onClose={() => setAnchor()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          {...other}
        >
          {content}
        </Popover>
        {childClone}
      </Fragment>
    );
  }
);
