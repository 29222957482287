import React from 'react';
import { Button, Popover, Menu, Icon } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import './Layout.css';

function NavItem({ item, crumbs, small, user }) {
  const history = useHistory();

  function changeRoute(path) {
    history.push(path);
  }

  function checkActive(item) {
    const crumbPaths = crumbs ? crumbs.map(crumb => crumb.path).slice(1) : [];
    if (item.child) {
      return item.child.filter(child => checkActive(child)).length > 0;
    } else {
      return crumbPaths.includes(item.path);
    }
  }

  const NavButton = () => (
    <div>
      {user !== true && (
        <div>
          <Button
            className={
              checkActive(item, crumbs)
                ? 'nav-button bp3-intent-primary'
                : 'bp3-minimal nav-button'
            }
            onClick={() => changeRoute(item.path)}
            style={
              checkActive(item, crumbs)
                ? {
                    textAlign: 'center',
                    margin: 'auto',
                    marginTop: 30,
                    marginLeft: 15,
                    backgroundColor: '#fff'
                  }
                : {
                    textAlign: 'center',
                    margin: 'auto',
                    marginTop: 30,
                    marginLeft: 15
                  }
            }
          >
            <span
              style={
                checkActive(item, crumbs)
                  ? { color: '#0747a6' }
                  : { color: '#fff' }
              }
            >
              {item.user ? (
                <img
                  style={{ borderRadius: '50%' }}
                  className={'img-fluid'}
                  src={item.user}
                />
              ) : (
                <Icon
                  icon={item.icon}
                  iconSize={20}
                  style={{ color: '#fff' }}
                />
              )}
              <br />
              {small !== true && (
                <span style={{ fontSize: 20 }}>{item.label}</span>
              )}
            </span>
          </Button>
          <br />
        </div>
      )}
      {user === true && (
        <Button
          className={
            checkActive(item, crumbs)
              ? 'nav-button bp3-intent-primary'
              : 'bp3-minimal nav-button'
          }
          onClick={() => changeRoute(item.path)}
          style={
            checkActive(item, crumbs)
              ? {
                  textAlign: 'center',
                  margin: 'auto',
                  marginLeft: 20,
                  backgroundColor: '#000'
                }
              : {
                  textAlign: 'center',
                  margin: 'auto',
                  marginLeft: 20
                }
          }
        >
          <span
            style={
              checkActive(item, crumbs)
                ? { color: '#0747a6' }
                : { color: '#000' }
            }
          >
            <img
              style={{ borderRadius: '50%', paddingTop: 10 }}
              className={'img-fluid'}
              src={item.user}
              width={30}
            />
          </span>
        </Button>
      )}
    </div>
  );

  function renderMenu(item) {
    function renderItem(item, index) {
      return (
        <Menu.Item
          key={index}
          active={checkActive(item, crumbs)}
          text={item.label}
          icon={item.icon}
          onClick={() => changeRoute(item.path)}
        >
          {item.child &&
            item.child.map((child, index) => renderItem(child, index))}
        </Menu.Item>
      );
    }

    return (
      <Menu style={{ backgroundColor: 'white', color: 'black' }}>
        <span style={{ textAlign: 'center' }}>
          <b>{item.label}</b>
          <hr />
        </span>
        {item.child.map((child, index) => renderItem(child, index))}
      </Menu>
    );
  }

  return item.child ? (
    <span style={{ textAlign: 'center' }}>
      <Popover
        content={renderMenu(item)}
        interactionKind="hover"
        position={user !== true ? 'right' : 'bottom'}
        autoFocus={false}
        hoverOpenDelay={0}
        hoverCloseDelay={0}
        minimal={true}
      >
        <NavButton />
      </Popover>
    </span>
  ) : (
    <span style={{ textAlign: 'center' }}>
      <Popover
        content={
          <Menu style={{ backgroundColor: 'white', color: 'black' }}>
            <span style={{ textAlign: 'center' }}>{item.label}</span>
          </Menu>
        }
        interactionKind="hover"
        position={user !== true ? 'right' : 'bottom'}
        autoFocus={false}
        hoverOpenDelay={0}
        hoverCloseDelay={0}
        minimal={true}
      >
        <NavButton />
      </Popover>
    </span>
  );
}

export default NavItem;
