import React, { useEffect } from 'react';
import { ButtonGroup, Button } from '@blueprintjs/core';
import { useFormik } from 'formik';
import useCheck from '../common/hooks/useCheck';
import { FlatForms } from './Forms/Forms';
import Popover from './Popover';
import Selector from './FieldSelector/Selector';
import { useLocalStorage } from '../common/hooks/storage';
import './Filter.css';

const groupAttribute = values => {
  //-----group custom attributes into atts[]
  let result = {};
  for (const key in values) {
    if (key.includes(':')) {
      result.atts = { ...result.atts, [key.slice(1)]: values[key] };
    } else {
      result[key] = values[key];
    }
  }

  return result;
};

const useControlledFormik = (inputs, onSubmit) => {
  const formik = useFormik({
    initialValues: {},
    onSubmit: values => onSubmit(groupAttribute(values))
  });

  useEffect(() => {
    const formikKeys = Object.keys(formik.values);
    const inputKeys = inputs.map(input => input.field);
    for (let key of formikKeys) {
      if (!inputKeys.includes(key)) formik.setFieldValue(key, null);
    }
  }, [inputs]);

  return formik;
};

const Form = ({ inputs, onSubmit, loading }) => {
  const [cache, setCache] = useLocalStorage('filter');

  const fieldChecker = useCheck(
    inputs,
    'field',
    checker => setCache(checker.checks),
    cache
  );

  const formik = useControlledFormik(fieldChecker.checkedItems, onSubmit);

  const clearAll = () => {
    const formikKeys = Object.keys(formik.values);
    for (let key of formikKeys) {
      formik.setFieldValue(key, null);
    }
    formik.handleSubmit();
  };

  return (
    <div className="filter-wrapper">
      <div className="filter-buttons">
        <ButtonGroup>
          <Button
            text="Tìm kiếm"
            intent="primary"
            icon="search"
            onClick={formik.handleSubmit}
            loading={loading}
          />
          <Button icon="cross" onClick={clearAll} />
        </ButtonGroup>
        <Selector checker={fieldChecker} />
      </div>
      <div className="filter-forms">
        <FlatForms formik={formik} inputs={fieldChecker.checkedItems} />
        {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
      </div>
    </div>
  );
};

export default React.memo(({ children, ...props }) => {
  return (
    <Popover content={<Form {...props} />}>
      {children || <Button icon="search" />}
    </Popover>
  );
});
